import Creator1Avatar from '../assets/avatar/creator1.png';
import Creator2Avatar from '../assets/avatar/creator2.png';
import Creator3Avatar from '../assets/avatar/creator3.png';
import Creator4Avatar from '../assets/avatar/creator4.png';
import Creator5Avatar from '../assets/avatar/creator5.png';

const avatars = [
    Creator1Avatar,
    Creator2Avatar,
    Creator3Avatar,
    Creator4Avatar,
    Creator5Avatar,
];

export function randomAvatar() {
    return avatars[Math.floor(Math.random() * avatars.length)];
}

export function hashAvatar(hash: string) {
    const index = parseInt(hash.slice(-1), 16);
    return avatars[index % avatars.length];
}