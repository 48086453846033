import React from 'react';

import './CircleTimer.css';
import { Alert, Card } from 'react-bootstrap';

interface CircleTimerProps {
    seconds: number;
    blocks: number;
    canStart: boolean;
    started: boolean;
}

class CircleTimer extends React.Component<CircleTimerProps> {

    toHHMM(seconds: number): string {
        if (seconds < 0) {
            return '00:00';
        }
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - hours * 3600) / 60);
        return `${hours < 10 ? "0" + hours : hours}h ${minutes < 10 ? "0" + minutes : minutes}m`
    }

    render() {
        const { seconds, blocks, canStart, started } = this.props;
        return (
            <div>
                {blocks > 0 ? <div style={{ display: 'flex' }}>
                    <Card style={{
                        width: '50%',
                    }}>
                        <Card.Header>countdown</Card.Header>
                        <Card.Body>
                            <h4>{this.toHHMM(seconds)}</h4>
                        </Card.Body>
                    </Card>
                    <Card style={{
                        width: '50%',
                        marginLeft: '10px'
                    }}>
                        <Card.Header>blocks</Card.Header>
                        <Card.Body>
                            <h4>{blocks + ''}</h4>
                        </Card.Body>
                    </Card>
                </div> : null
                }
                {canStart && !started ? <Alert variant='warning'>
                        Ready to launch
                    </Alert> : null}
            </div>
        );
    }
}

export default CircleTimer;