import copy from "copy-to-clipboard";
import React from "react";
import { Button, Modal } from "react-bootstrap";

interface OpenInWeb3BrowserProps {
    show: boolean;
    url?: string;
    onCloseClick?: () => void;
}

interface OpenInWeb3BrowserState {
}

class OpenInWeb3Browser extends React.Component< OpenInWeb3BrowserProps, OpenInWeb3BrowserState> {

    constructor(props: OpenInWeb3BrowserProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        const url = this.props.url ?? window.location.href;
        const { show } = this.props;
        return (
            <div>
                <Modal show={show} centered onHide={() => {
                    if (this.props.onCloseClick) {
                        this.props.onCloseClick();
                    }
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Open in DApp Browser</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        A dapp browser is required to interact with this page. Please open this page in a dapp browser.
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button variant="warning" onClick={() => {
                                window.location.href = `/redirect?re=${encodeURIComponent(url)}`;
                            }}>Open in Web3 Browser</Button>

                            <Button variant="link" onClick={() => {
                                copy(url);
                            }}>Copy URL</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default OpenInWeb3Browser;