import React from "react";

import "./NFTList.css";
import { ethers, Provider, TransactionReceipt, TransactionResponse } from "ethers";
import { isInWeb3Browser } from "../../utils/dapp";
import { Chain, CHAINS } from "../../config/chains";
import { IFairLaunchFactory } from "../../abi/IFairLaunchFactory.abi";
import { IUniLocker } from "../../abi/IUniLocker.abi";
import { Col } from "react-bootstrap";
import LPLockCard from "../unilocker/LPLockCard";
import EmptyImage from "../../assets/image/empty.png";

interface NFTListProps {
    provider?: Provider;
    contractAddress?: string;
    address?: string;
}

interface NFTListState {
    nfts: Array<bigint | number>;
    loading: boolean;
    chain?: Chain;
    showTxResult: boolean;
    txHash?: string;
    txReceipt?: TransactionReceipt | null;
}

class NFTList extends React.Component<NFTListProps, NFTListState> {
    constructor(props: NFTListProps) {
        super(props);
        this.state = {
            nfts: [],
            loading: false,
            chain: undefined,
            showTxResult: false,
            txHash: undefined,
            txReceipt: undefined,
        };
    }

    componentDidMount(): void {
        this.init();
    }

    componentDidUpdate(prevProps: Readonly<NFTListProps>, prevState: Readonly<NFTListState>, snapshot?: any): void {
        // provider changed
        if (!prevProps.provider && this.props.provider) {
            this.init();
        }

    }

    init = async (): Promise<void> => {
        // try connect with popup
        if (!isInWeb3Browser()) {
            return;
        }
        if (this.props.provider === undefined) {
            console.error("provider is empty")
            return;
        }

        const provider = this.props.provider;
        const network = await provider.getNetwork();
        const chainId = network.chainId;
        const chain = CHAINS[Number(chainId)];
        if (chain === undefined) {
            return;
        } else {
            this.setState({
                chain: chain,
            });
        }

        if (!this.props.address) {
            return
        }
        this.setState({
            loading: true,
        });

        const fairLaunchFactoryContract = new ethers.Contract(
            chain.fairLaunchFactory,
            IFairLaunchFactory,
            this.props.provider
        );

        const locker = await fairLaunchFactoryContract.locker();

        const lockerContract = new ethers.Contract(
            locker,
            IUniLocker,
            this.props.provider
        );

        const balance = await lockerContract.balanceOf(this.props.address);

        console.log('balance:', balance);
        const nfts = [];
        for (let i = 0; i < balance; i++) {
            const nft = await lockerContract.tokenOfOwnerByIndex(this.props.address, i);
            nfts.push(nft);
        }

        // unloading
        this.setState({
            loading: false,
            nfts: nfts,
        });
    }

    render() {
        const { loading, nfts } = this.state;
        return (
            <div className="NFTList">
                <div className="z4" style={{ marginLeft: '1.6rem', marginRight: "1.6rem" }}>
                    91% of the transaction fee goes to the Creator as rewards, and 9% goes to Rocket Protocol.
                </div>
                {!loading && nfts.length === 0 ? 
                    <div style={{
                        marginTop: '5rem',
                        marginBottom: '5rem',
                    }}>
                        <img src={EmptyImage} alt="empty" style={{ width: '10rem', margin: 'auto', display: 'block' }} />
                    </div>
                 : <div style={{
                    // gap: '0.8rem',
                    padding: '1rem',
                    flexWrap: 'wrap',
                    display: 'flex',

                }}>
                    {this.state.nfts.map((nft: bigint | number) => {
                        return <Col lg={6} sm={1} md={6} key={nft}><div style={{
                            padding: '1rem',
                        }}>{this.props.provider && this.state.chain !== undefined ? <LPLockCard
                            tokenId={nft}
                            chain={this.state.chain}
                            provider={this.props.provider}
                            onClaimSuccess={async (tx: TransactionResponse) => {
                                console.log('tx:', tx);
                                // show tx result
                                await tx.wait()
                                const receipt = await this.props.provider?.getTransactionReceipt(tx.hash);
                                this.setState({
                                    showTxResult: true,
                                    txHash: tx.hash,
                                    txReceipt: receipt,
                                });
                            }} /> : null}
                        </div>

                        </Col>
                    })}
                </div>
                }
            </div>
        );
    }
}

export default NFTList