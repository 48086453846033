import React from 'react';
import './IndexPage.css';
import Header from '../components/header/Header';
import {
    connect,
    isInWeb3Browser,
    toEIP55,
    onAccountChange,
    onChainSwitch,
    tryConnectSilent
} from '../utils/dapp';
import { CHAINS } from '../config/chains';

import { API } from '../config/api';
import { Button, Col, Form, InputGroup, Modal, Nav, Pagination, } from 'react-bootstrap';

import ProjectCard, { Project } from '../components/project/ProjectCard';
import { ethers, Provider, TransactionResponse } from 'ethers';
import Footer from '../components/footer/Footer';
import { IFairLaunchFactory } from '../abi/IFairLaunchFactory.abi';
import { IUniLocker } from '../abi/IUniLocker.abi';
import LPLockCard from '../components/unilocker/LPLockCard';

import ErrorIcon from '../assets/icon/error.png';
import SuccessIcon from '../assets/icon/success.png';
import WaitingIcon from '../assets/icon/waiting.png';

import XIcon from '../assets/image/x-1.png';
import TelegramIcon from '../assets/image/telegram-1.png';
import EmptyImage from '../assets/image/empty.png';
import OpenInWeb3Browser from '../components/web3opener/OpenInWeb3Browser';

import FairImage from '../assets/image/fair.png';
import SecureImage from '../assets/image/secure.png';
import FunImage from '../assets/image/fun.png';

import AveLogo from '../assets/partner/ave.png';
import BaseLogo from '../assets/partner/base.png';
import BNBLogo from '../assets/partner/bnb.png';
import CyberScopeLogo from '../assets/partner/cyberscope.png';
import EthLogo from '../assets/partner/eth.png';
import LBankLogo from '../assets/partner/lbank.png';
import RocketLogo from '../assets/partner/rocket.png';
import TPLogo from '../assets/partner/tp.png';
import Token2049Logo from '../assets/partner/token2049.png';

import "./PortalPage.css";


interface HomePageDetailProps {
}

interface HomePageDetailState {
    chain: any;
    address: string;
    page: number;
    total: number;
    size: number;
    nomore: boolean;
    loading: boolean;
    projects: any[];
    myProjects: any[];
    recommadation?: Project;
    provider?: Provider;
    blockNumber?: number;
    nfts: Array<bigint | number>;

    orderByName: string;
    direction: string;
    keyword: string;
    showWeb3Open: boolean;

    [propName: string]: any;
}

class HomePage extends React.Component<HomePageDetailProps, HomePageDetailState> {
    constructor(props: HomePageDetailProps) {
        super(props);
        this.state = {
            chain: null,
            address: "",
            page: 0,
            size: 12,
            total: 0,
            nomore: false,
            loading: false,
            keyword: '',
            projects: [],
            recommadation: undefined,
            myProjects: [],
            newTab: 0,

            provider: undefined,

            tabs: ['Mine', 'Terminal'],
            tab: 1,
            blockNumber: 0,

            subTab: 1,
            myPage: 0,
            mySize: 10,
            myTotal: 0,

            nfts: [],
            searchKey: '',

            showTxResult: false,
            txHash: '',
            txReceipt: null,

            orderByName: 'createdAt',
            direction: 'desc',

            showWeb3Open: false,
        };
    }

    componentDidMount(): void {
        this.initSilent();
        // this.loadData();
    }

    initNoWeb3Browser = async (): Promise<void> => {
        // fetch API + '/currentBlock'

        const res = await fetch(API + '/currentBlock');
        const result = await res.json();
        if (result.code === 1) {
            this.setState({
                blockNumber: result.data,
            });
        }
    }

    initSilent = async (): Promise<void> => {
        if (!isInWeb3Browser()) {
            this.initNoWeb3Browser();
            return;
        }
        // const result = await conn();
        const result = await tryConnectSilent();
        console.log('result = ', result)
        if (result === null) {
            return;
        }

        const { chainId, provider } = result;
        const numChainId = Number(chainId);

        if (CHAINS[numChainId] === undefined) {
            return;
        }

        const blockNumber = await provider.getBlockNumber();

        this.setState({
            chain: CHAINS[numChainId],
            provider: provider,
            blockNumber: blockNumber,
        });

        if (result.address !== undefined) {
            this.setState({
                address: toEIP55(result.address),
            });
        }

        console.log('conn block number:', blockNumber)
        this.initTimer(CHAINS[numChainId], provider);
        this.watchChange();
    }

    watchChange = async (): Promise<void> => {
        onAccountChange((accounts: any) => {
            this.setState({
                address: toEIP55(accounts[0])
            })
        })

        onChainSwitch((chainId: number | string) => {
            console.log('chainId:', chainId)
            this.setState({
                chain: CHAINS[Number(chainId)],
            });
            this.initSilent();
            if (CHAINS[Number(chainId)] === undefined) {
                // not support
                alert('Chain not supported:' + chainId)
                return;
            }
        })
    }

    init = async (): Promise<void> => {
        if (!isInWeb3Browser()) {
            alert('Please install MetaMask.');
            return;
        }

        try {
            const result = await connect()

            if (result === null) {
                return;
            }

            const { chainId, address } = result;
            const numChainId = Number(chainId);

            if (CHAINS[numChainId] === undefined) {
                // not support
                alert('Chain not supported:' + chainId)
                return;
            }

            console.log('chainId:', chainId)
            this.setState({
                address: toEIP55(address),
                chain: CHAINS[numChainId],
                provider: result.provider
            });

            this.watchChange();

        } catch (error) {
            console.error(error)
        }
    }

    initTimer = (chain: any, provider: Provider): void => {
        const that = this

        provider.on('block', async (blockNumber: number) => {
            that.setState({
                blockNumber: blockNumber,
            });
        })

    }


    getUrl = (): string => {
        interface Params {
            status?: number;
            page: number;
            size: number;
            sort: Array<string>;
        }
        const params: Params = {
            page: this.state.page,
            size: this.state.size,
            sort: ['weight,desc', `${this.state.orderByName},${this.state.direction}`],
        }

        let uri = `page=${params.page}&size=${params.size}&sort=${params.sort.join('&sort=')}`;
        if (params.status !== undefined) {
            uri += `&status=${params.status}`;
        }
        const keyword = this.state.searchKey ? !/^0x[a-zA-Z0-9]{40}$/.test(this.state.searchKey) ? this.state.searchKey : '' : '';
        const url = new URL(`${API}/project/list?${uri}&keyword=${keyword}`);
        return url.toString();
    }

    loadData = async (): Promise<void> => {
        // load data from central server
        // const url = API + '/project/list';
        // const url = `${API}/project/list?page=${this.state.page}&size=${this.state.size}&order=weight,desc&order=createdAt,desc`;
        const url = this.getUrl();

        this.setState({
            loading: true,
            projects: [],
        });

        try {
            const response = await fetch(url);
            const result = await response.json();

            if (result.code === 1) {
                console.log(result.data);
                this.setState({
                    total: result.data.totalElements,
                    nomore: result.data.content.length === 0,
                    // projects: [...this.state.projects, ...result.data.content],
                    projects: result.data.content,
                });
            } else {
                alert('Failed to load data');
            }

            this.setState({
                loading: false,
            });

        } catch (error) {
            console.error(error);
            this.setState({
                loading: false,
            });
        }
    }

    loadMyParticipated = async (): Promise<void> => {
        if (!this.state.address) {
            return
        }
        const url = `${API}/event/address/${this.state.address}?page=${this.state.myPage}`;

        this.setState({
            loading: true,
        });

        try {
            const response = await fetch(url);
            const result = await response.json();

            if (result.code === 1) {
                console.log(result.data);

                //iterator content, project is element.project
                const projects: Array<Project> = []
                result.data.content.forEach((element: any) => {
                    projects.push(element.project)
                })

                this.setState({
                    myTotal: result.data.totalElements,
                    myProjects: projects,
                });
            } else {
                alert('Failed to load data');
            }

            this.setState({
                loading: false,
            });

        } catch (error) {
            console.error(error);
            this.setState({
                loading: false,
            });
        }
    }

    //load i started
    loadMyStarted = async (): Promise<void> => {
        if (!this.state.address) {
            return
        }
        const url = `${API}/project/list/${this.state.address}?page=${this.state.myPage}`;

        this.setState({
            loading: true,
        });

        try {
            const response = await fetch(url);
            const result = await response.json();

            if (result.code === 1) {
                console.log(result.data);
                this.setState({
                    myTotal: result.data.totalElements,
                    myProjects: result.data.content,
                });
            } else {
                alert('Failed to load data');
            }

            this.setState({
                loading: false,
            });

        } catch (error) {
            console.error(error);
            this.setState({
                loading: false,
            });
        }
    }

    loadNFTs = async (): Promise<void> => {
        if (!this.state.address) {
            return
        }
        // loading
        this.setState({
            loading: true,
        });

        const fairLaunchFactoryContract = new ethers.Contract(
            this.state.chain.fairLaunchFactory,
            IFairLaunchFactory,
            this.state.provider
        );

        const locker = await fairLaunchFactoryContract.locker();
        console.log("locker:", locker);

        const lockerContract = new ethers.Contract(
            locker,
            IUniLocker,
            this.state.provider
        );

        const balance = await lockerContract.balanceOf(this.state.address);

        console.log('balance:', balance);
        const nfts = [];
        for (let i = 0; i < balance; i++) {
            const nft = await lockerContract.tokenOfOwnerByIndex(this.state.address, i);
            nfts.push(nft);
        }

        // unloading
        this.setState({
            loading: false,
            nfts: nfts,
        });
    }

    render(): React.ReactNode {
        return (
            <div style={
                { marginBottom: '3rem', }
            }>
                <Header showMessage={false} blocknumber={this.state.blockNumber} chain={this.state.chain} onConnectClick={() => {
                    this.init();
                }} address={this.state.address} />
                <div className="indexPageBody">
                    <div className='z3 center padding' style={{
                        marginTop: "0.5rem",
                    }}>
                        Best fair launch platform for MEME coins on ETH. EPUMP.FUN helps every community to quickly start coins in a FairMint manner – Fair, Secure, Fun!
                    </div>
                    <div className='z3 center flex align-center justify-center' style={{
                    }}>
                        <div className='align-center justify-center cursor flex' onClick={() => {
                            document.location.href = 'https://x.com/epumpfun';
                        }}>
                            <img src={XIcon} alt="x" style={{
                                width: '1rem',
                                marginRight: '0.5rem',
                            }} />
                            <div>Twitter</div>
                        </div>

                        {/* telegram */}
                        <div className='align-center justify-center cursor margin-left flex' onClick={() => {
                            document.location.href = 'https://t.me/epumpfun';
                        }}>
                            <img src={TelegramIcon} alt="telegram" style={{
                                width: '1rem',
                                marginRight: '0.5rem',
                            }} />
                            <div>Telegram</div>
                        </div>

                        <div className='margin-left cursor' onClick={() => {
                            // TODO after release, chagnge to https://factory.rocket.meme/ 
                            document.location.href = process.env.REACT_APP_HELP_URL ?? 'https://rocketmeme.gitbook.io/';
                        }}>
                            [ How it works ]
                        </div>

                        <div className='margin-left cursor' onClick={() => {
                            document.location.href = 'https://github.com/rocketprotocollab';
                        }}>
                            [ Github ]
                        </div>

                        <div className='margin-left cursor' onClick={() => {
                            document.location.href = process.env.REACT_APP_AUDIT_REPORT ?? 'https://rocketmeme.gitbook.io/';
                        }}>
                            [ Contract Audit Report ]
                        </div>

                    </div>
                    <div className="indexPageSearchBox">
                        <div className="link z2" onClick={() => {
                            // TODO after release, chagnge to https://factory.rocket.meme/ 
                            document.location.href = '/factory';
                        }}>[start a new coin]</div>

                        {/* <div className='padding-left padding-right' style={{ width: '100%' }}>
                            <hr style={{ color: '#2f2f2f' }}></hr>
                        </div> */}
                        {/* <div style={{
                            fontSize: "1.5rem",
                        }}>
                            <div className='z2 hightlight-yallow italic bold'>HOTTEST PUMP! LFG</div>
                        </div> */}
                        <div className="middleCenter margin-top">
                            <div style={{ width: '100%' }}>
                                {this.state.recommadation ? <ProjectCard project={this.state.recommadation} blockSeconds={this.state.chain?.blockSeconds} blocknumber={this.state.blockNumber} provider={this.state.provider}></ProjectCard> : null}
                            </div>
                        </div>
                        {/* search box */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '1rem',
                            marginBottom: '1rem',
                        }}>
                            <Form>
                                <InputGroup>
                                    <Form.Control
                                        style={{
                                            width: '20rem',
                                        }}
                                        placeholder="Search by name or address"
                                        value={this.state.searchKey}
                                        maxLength={43}
                                        onChange={(e) => {
                                            this.setState({
                                                searchKey: e.target.value,
                                                // page: 0,
                                                // nomore: false,
                                            });
                                            // this.loadData();
                                        }}
                                    />
                                    <Button variant="outline-secondary" style={{
                                        backgroundColor: '#1bb2da',
                                        color: 'white',
                                    }} onClick={() => {
                                        this.setState({
                                            page: 0,
                                            nomore: false,
                                        }, () => {
                                            this.loadData();
                                        });
                                    }}>Search</Button>
                                </InputGroup>
                            </Form>
                        </div>

                    </div>
                </div>
                {/* projects */}
                {this.state.tab === 1 ? <div style={{
                    marginLeft: "2rem",
                    marginRight: "2rem",
                    marginTop: "1rem",
                }}>
                    {this.state.projects.length > 0 ? <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }}>
                        {this.state.projects.map((project: Project) => {
                            return <Col sm={12} md={4} lg={4} key={project.address}>
                                <ProjectCard project={project} blockSeconds={this.state.chain?.blockSeconds} blocknumber={this.state.blockNumber} provider={this.state.provider} ></ProjectCard>
                            </Col>
                        })}
                    </div> : null}
                    {this.state.projects.length === 0 && this.state.keyword !== '' ? <div style={{
                        marginTop: '5rem',
                        marginBottom: '5rem',
                    }}>
                        <img src={EmptyImage} alt="empty" style={{ width: '10rem', margin: 'auto', display: 'block' }} />
                    </div> : null}
                    {/* pagination */}
                    {this.state.projects.length === 0 && this.state.keyword !== '' ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <Pagination>
                            <Pagination.Prev disabled={this.state.page === 0} onClick={() => {
                                if (this.state.page === 0) {
                                    return;
                                }
                                this.setState({
                                    page: this.state.page - 1,
                                }, () => {
                                    this.loadData()
                                });

                            }} />
                            <Pagination.Item disabled>{this.state.page + 1}</Pagination.Item>
                            <Pagination.Next disabled={this.state.total <= (this.state.page + 1) * this.state.size} onClick={() => {
                                if (this.state.total <= (this.state.page + 1) * this.state.size) {
                                    return;
                                }
                                this.setState({
                                    page: this.state.page + 1,
                                }, () => {
                                    this.loadData()
                                });
                            }} />
                        </Pagination>
                    </div> : null}
                    {/* end of projects */}
                    {/* start rubish */}
                    <div className="divide"></div>
                    <div className="portalSegment">
                        {/* <div className="portalSegmentTitle">Adopting the Rocket Protocol will bring you...</div> */}
                        <div className="portalSegmentProjects">

                            <Col sm={12} lg={4} md={4} className="portalSegmentFeature">
                                <div className="portalSegmentItemImage">
                                    <img src={FairImage} alt="" />
                                </div>
                                <div className="portalSegmentItemInfo">
                                    <div className="z2 portalSegmentItemTitle pointer" onClick={() => {
                                        document.location.href = 'https://rocketmeme.gitbook.io/eth/advantages';
                                    }}>Fair</div>
                                    <div className="portalSegmentItemLi z3">
                                        - 60% FairMint - 40% added to LP
                                    </div>
                                    <div className="portalSegmentItemLi z3">
                                        - No pre-allocation
                                    </div>
                                    <div className="portalSegmentItemLi z3">
                                        - No front-running
                                    </div>
                                </div>
                            </Col>
                            <div className="only-mobile" style={{ height: '1rem', width: '100%' }}></div>
                            <Col sm={12} lg={4} md={4} className="portalSegmentFeature">

                                <div className="portalSegmentItemImage">
                                    <img src={SecureImage} alt="" />
                                </div>
                                <div className="portalSegmentItemInfo">
                                    <div className="portalSegmentItemTitle z2 pointer" onClick={() => {
                                        document.location.href = 'https://rocketmeme.gitbook.io/eth/advantages';
                                    }}>Secure</div>
                                    <div className="portalSegmentItemLi z3">- No rugs, LP automatically and permanently locked</div>
                                    <div className="portalSegmentItemLi z3">- Funds kept in coin smart contract</div>
                                    <div className="portalSegmentItemLi z3">- Audited by top contract aduitor</div>
                                </div>
                            </Col>
                            <div className="only-mobile" style={{ height: '1rem', width: '100%' }}></div>
                            <Col sm={12} lg={4} md={4} className="portalSegmentFeature">
                                <div className="portalSegmentItemImage">
                                    <img src={FunImage} alt="" />
                                </div>
                                <div className="portalSegmentItemInfo">
                                    <div className="portalSegmentItemTitle z2 pointer" onClick={() => {
                                        document.location.href = 'https://rocketmeme.gitbook.io/eth/advantages';
                                    }}>Fun</div>
                                    <div className="portalSegmentItemLi z3"> - Easy to participate, just send {process.env.REACT_APP_BASE_CURRENCY} to token CA</div>
                                    <div className="portalSegmentItemLi z3"> - Creator earns LP transaction fee rewards</div>
                                    <div className="portalSegmentItemLi z3"> - Encourage co-building, make memes more fun!</div>
                                </div>
                            </Col>
                        </div>
                    </div>
                    <div className="divide"></div>
                    <div className='portalSegment'>
                        <div className="portalSegmentTitle">Partners</div>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '1rem',
                            marginTop: '1rem',

                        }}>
                            <Partner name="Rocket" url="" logo={RocketLogo} />
                            <Partner name="Ave" url="" logo={AveLogo} />
                            {/* tp */}
                            <Partner name="TP" url="" logo={TPLogo} />
                            {/* lbank */}
                            <Partner name="LBank" url="" logo={LBankLogo} />
                            {/* cyberscope */}
                            <Partner name="CyberScope" url="" logo={CyberScopeLogo} />
                            {/* eth */}
                            <Partner name="ETH" url="" logo={EthLogo} />
                            {/* base */}
                            <Partner name="Base" url="" logo={BaseLogo} />
                            {/* bnb */}
                            <Partner name="BNB" url="" logo={BNBLogo} />
                            {/* token2049 */}
                            <Partner name="Token2049" url="" logo={Token2049Logo} />
                        </div>
                    </div>
                    {/* end of garbage  */}
                </div> : null}
                {this.state.tab === 0 ? <div>
                    <div style={{
                        margin: "1rem",
                        display: "flex",
                    }}>
                        <Nav variant="pills" activeKey={this.state.subTab} onSelect={(key) => {
                            this.setState({
                                subTab: Number(key),
                            });

                            if (Number(key) === 1) {
                                // my part
                                // set page to 0
                                this.setState({
                                    myPage: 0,
                                    myProjects: [],
                                });
                                this.loadMyParticipated();
                            } else if (Number(key) === 3) {
                                // locked lp
                                this.loadNFTs();
                            } else {
                                // my started
                                this.setState({
                                    myPage: 0,
                                    myProjects: [],
                                });
                                this.loadMyStarted();
                            }
                        }}>
                            <Nav.Item>
                                <Nav.Link eventKey="1">I participated</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2">I started</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="3">my locked LP</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* all my projects */}
                    {this.state.subTab === 1 || this.state.subTab === 2 ? <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }}>
                        {this.state.myProjects.map((project: Project, index: number) => {
                            return <Col sm={12} md={4} lg={4} key={
                                index
                            }>
                                <ProjectCard project={project} blockSeconds={this.state.chain?.blockSeconds} blocknumber={this.state.blockNumber} provider={this.state.provider}></ProjectCard>
                            </Col>
                        })}
                    </div> : null}
                    {this.state.subTab === 3 ? <div>
                        {this.state.provider !== undefined ? <div style={{
                            // gap: '0.8rem',
                            padding: '1rem',
                            flexWrap: 'wrap',
                            display: 'flex',

                        }}>
                            {this.state.nfts.map((nft: bigint | number) => {
                                return <Col lg={6} sm={1} md={6} key={nft}><div style={{
                                    padding: '1rem',
                                }}><LPLockCard
                                        tokenId={nft}
                                        chain={this.state.chain}
                                        provider={this.state.provider}
                                        onClaimSuccess={async (tx: TransactionResponse) => {
                                            console.log('tx:', tx);
                                            // show tx result
                                            await tx.wait()
                                            const receipt = await this.state.provider?.getTransactionReceipt(tx.hash);
                                            this.setState({
                                                showTxResult: true,
                                                txHash: tx.hash,
                                                txReceipt: receipt,
                                            });
                                        }} /> </div></Col>
                            })}
                        </div> : null}
                    </div> : null}
                </div> : null}
                <Footer />

                {/* loading cover modal */}
                <Modal centered show={this.state.loading}>
                    <Modal.Body style={{ textAlign: 'center' }}>
                        Loading...
                    </Modal.Body>
                </Modal>

                {/* show tx result */}
                <Modal centered show={this.state.showTxResult}>
                    <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
                        <div>
                            {!this.state.txReceipt ? <img src={WaitingIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
                            {this.state.txReceipt && this.state.txReceipt.status === 1 ? <img src={SuccessIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
                            {this.state.txReceipt && this.state.txReceipt.status !== 1 ? <img src={ErrorIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
                        </div>
                        {this.state.txHash ? <div>
                            Transaction hash: <a href={
                                this.state.chain?.blockExplorer + '/tx/' + this.state.txHash
                            } target="_blank" rel="noreferrer"> {this.state.txHash.substring(0, 10) + '...' + this.state.txHash.substring(this.state.txHash.length - 8)}</a>
                        </div> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={async () => {
                            this.setState({
                                showTxResult: false
                            });
                        }}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                <OpenInWeb3Browser show={this.state.showWeb3Open} onCloseClick={() => {
                    this.setState({
                        showWeb3Open: false,
                    });
                }} />

                {/* <div style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    opacity: 0.1,
                    height: '98%',
                }}>
                    <img src="/images/aa2.png" style={{
                        maxHeight: '100%',
                    }} alt="bg"/>
                </div> */}
            </div >
        );
    }
}

interface PartnerProps {
    logo: any;
    name: string;
    url: string;
}

class Partner extends React.Component<PartnerProps> {
    render() {
        return (
            <div className="partner">
                <a href={this.props.url} target="_blank" rel="noreferrer">
                    <img src={this.props.logo} alt={this.props.name} style={{
                        width: '4rem',
                        borderRadius: '0.5rem',
                    }} />
                </a>
            </div>
        );
    }
}

function IndexPage() {
    return (
        <div>
            <HomePage />
        </div>
    );
}

export default IndexPage;