import React from 'react';
import Logo from '../assets/logo.svg';
import { Alert, Button, Card } from 'react-bootstrap';
import copy from 'copy-to-clipboard';

interface RedirectPageProps {
    re: string;
}

interface RedirectPageState {
    isIOS: boolean;
    isAndroid: boolean;
    isMobile: boolean;
}

class RedirectPage extends React.Component<RedirectPageProps, RedirectPageState> {

    constructor(props: RedirectPageProps) {
        super(props);
        this.state = {
            isIOS: false,
            isAndroid: false,
            isMobile: false,
        };
    }

    componentDidMount() {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        if (/android/i.test(userAgent)) {
            this.setState({
                isAndroid: true,
                isMobile: true,
            });
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            this.setState({
                isIOS: true,
                isMobile: true,
            });
        }
    }

    isValid = (): boolean => {
        // must starts with https://rocket.meme/ or https://app.uniswap or https://pancakeswap.finance
        const whitelist = [
            "https://epump.fun/",
            "https://app.uniswap.org/",
            "https://pancakeswap.finance/",
        ];
        for (const w of whitelist) {
            if (this.props.re.startsWith(w)) {
                return true;
            }
        }
        return false;
    }

    render() {
        return (
            <div>
                <div style={{
                    display: "flex",
                    padding: "1rem",
                    backgroundColor: "#333",
                    color: "#ffffff",

                }}>
                    <div style={{
                        // maxWidth: "800px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0 auto",
                        width: "100%",
                    }}>
                        <div className="headerLeft">
                            {/* logo */}
                            <img src={Logo} alt="logo" style={{
                                width: "2.2rem",
                                height: "2.2rem",
                                marginRight: "1rem",
                                cursor: "pointer",
                            }}
                                onClick={() => {
                                    window.location.href = '/';
                                }}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                }}>
                    <div style={{
                        maxWidth: "800px",
                        width: "100%",
                        margin: "1rem",
                    }}>
                        <Card>
                            <Card.Body>
                                <Alert variant="warning">
                                    You are about to be redirected to <br />
                                    &gt;&gt;&gt; <span style={{
                                        textDecoration: "underline",
                                    }}>{this.props.re}</span>&lt;&lt;&lt;.  <br />Please make sure you trust the source before proceeding.
                                </Alert>
                                <Card.Title>You can open the url with</Card.Title>
                                <div>
                                    <Button variant="link" onClick={() => {
                                        if (!this.isValid()) {
                                            alert("Invalid URL");
                                            return;
                                        }
                                        window.location.href = `https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(this.props.re)}`;
                                    }}>Coinbase Wallet</Button>
                                </div>
                                <div>
                                    <Button variant="link" onClick={() => {
                                        if (!this.isValid()) {
                                            alert("Invalid URL");
                                            return;
                                        }
                                        // remove header https://
                                        const url = this.props.re.substring(8);
                                        window.location.href = `https://metamask.app.link/dapp/${url}`;
                                    }}>Metamask Wallet</Button>
                                </div>

                                <div>
                                    <Button variant="link" onClick={() => {
                                        if (!this.isValid()) {
                                            alert("Invalid URL");
                                            return;
                                        }
                                        window.location.href = `imtokenv2://navigate/DappView?url=${encodeURIComponent(this.props.re)}`;
                                    }}>imToken Wallet</Button>
                                </div>

                                <div>
                                    <Button variant="link" onClick={() => {
                                        if (!this.isValid()) {
                                            alert("Invalid URL");
                                            return;
                                        }
                                        const encodedDappUrl = encodeURIComponent(this.props.re);
                                        const deepLink = `okx://wallet/dapp/url?dappUrl=${encodedDappUrl}`;
                                        const encodedUrl = `https://www.okx.com/download?deeplink=${encodeURIComponent(deepLink)}`;
                                        window.location.href = encodedUrl;
                                    }}>OKX Web3 Wallet</Button>
                                </div>
                                <div>
                                    <Button variant="link" onClick={() => {
                                        if (!this.isValid()) {
                                            alert("Invalid URL");
                                            return;
                                        }
                                        const params = {
                                            "url": this.props.re,
                                            "chain": "base",
                                            "source": "Rocket Protocol"
                                        }
                                        window.location.href = `tpdapp://open?params=${encodeURIComponent(JSON.stringify(params))}`;
                                    }}>Token Pocket Wallet</Button>
                                </div>
                                
                                

                                {/* <div>
                                    <Button variant="link" onClick={() => {
                                        if (!this.isValid()) {
                                            alert("Invalid URL");
                                            return;
                                        }
                                        window.location.href = this.props.re;
                                    }}>Binance Web3 Wallet</Button>
                                </div> */}

                                <Card.Title>You can also </Card.Title>
                                <div>
                                    <Button variant="link" onClick={() => {
                                        if (!this.isValid()) {
                                            alert("Invalid URL");
                                            return;
                                        }
                                        window.location.href = this.props.re;
                                    }}>Open in Browser</Button>
                                </div>
                                <div>
                                    <Button variant="link" onClick={() => {
                                        if (!this.isValid()) {
                                            alert("Invalid URL");
                                            return;
                                        }
                                        copy(this.props.re);
                                    }}>Copy URL</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

export default RedirectPage;