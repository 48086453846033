import React from 'react';

import './ProjectCard.css';
import { Provider, ethers } from 'ethers';
import { CHAINS } from '../../config/chains';
import { API } from '../../config/api';
import { hashAvatar } from '../../utils/avatar';
import { formatTinyPrice } from '../../utils/format';
import { getPrice, Price } from '../../utils/project';
import { FairLaunchLimitAmountToken } from '../../abi/FairLaunchLimitAmountToken.abi';

export interface Project {
    address: string,
    name: string,
    symbol: string,
    meta: string,
    totalSupply: bigint,
    hash: string,
    marketCap: bigint,
    liquidity: bigint,
    creator: string,
    rate: number,
    [propName: string]: any;
}

interface ProjectCardProps {
    project: Project;
    provider?: Provider | null;
    blocknumber?: number;
    blockSeconds?: number;
}

export interface ProjectMeta {
    [key: string]: any;
    image?: string;
    X?: string;
    youtube?: string;
    discord?: string,
    telegram?: string,
    gitbook?: string,
    website?: string,
    description?: string,
}

export interface ProjectMarketInfo {
    balance: bigint;
    started?: boolean;
    price: number | bigint;
    shares: number | bigint;
    totalSupply: bigint;
    minted: bigint;
    maxUnitsOfEachAddress: number | bigint;
    amountPerShare: number | bigint;
    progress: bigint | number;
}

export interface ProjectMetaItem {
    trait_type: string;
    value: string;

}

interface ProjectCardState {
    meta?: ProjectMeta;
    market?: ProjectMarketInfo;
    blocknumber?: number;
    price?: Price | undefined;
}

class ProjectCard extends React.Component<ProjectCardProps, ProjectCardState> {

    constructor(props: ProjectCardProps) {
        super(props);
        this.state = {
            meta: this.parseMeta(props.project.meta) || undefined,
            market: undefined,
            blocknumber: props.blocknumber || 0,
            price: undefined
        };
    }

    componentDidMount(): void {
        this.initChainData();
        this.loadPrice();
    }

    componentDidUpdate(prevProps: Readonly<ProjectCardProps>, prevState: Readonly<ProjectCardState>, snapshot?: any): void {
        if (prevProps.blocknumber !== this.props.blocknumber) {
            // this.initChainData();
            this.setState({
                blocknumber: this.props.blocknumber
            });
        }
        if (prevProps.provider === undefined && this.props.provider) {
            // this.initChainData();
        }
    }

    loadPrice = async () => {
        const priceObj = await getPrice(this.props.project.address);
        console.log('price obj = ', priceObj);
        this.setState({
            // price: theMarketCap
            price: priceObj
        });
    }

    initRESTData = async (): Promise<void> => {
        const response = await fetch(`${API}/project/rpc?address=${this.props.project.address}`);
        const data = await response.json();
        if (data.code === 1) {
            // if data.data is string, parse it to json
            const info = typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
            this.setState({
                market: info,
                blocknumber: info.blocknumber
            });
        }
    }

    initChainData = async (): Promise<void> => {
        this.initRESTData();
        if (!this.props.provider) {
            return;
        }
        const network = await this.props.provider.getNetwork();
        const chain = CHAINS[Number(network.chainId)];

        if (!chain) {
            console.error('chain is not supported');
            return;
        }

        try {
            const contract = new ethers.Contract(this.props.project.address, FairLaunchLimitAmountToken, this.props.provider);

            const started = await contract.started();
            const balance = await this.props.provider.getBalance(this.props.project.address);
            const totalSupply = await contract.totalSupply();
            const minted = await contract.minted();
            const maxUnitsOfEachAddress = await contract.maxUnitsOfEachAddress();
            const amountPerShare = await contract.amountPerShare();
            const shares: bigint = totalSupply * BigInt(60) / BigInt(100) / amountPerShare;
            const price = await contract.price();

            // progress
            const progress: bigint = minted * BigInt(100) / amountPerShare / shares;

            const market: ProjectMarketInfo = {
                started,
                balance,
                shares,
                totalSupply,
                minted,
                maxUnitsOfEachAddress,
                amountPerShare,
                progress,
                price: price
            };

            this.setState({
                market
            });
        } catch (e) {
        }
    }

    toKMBT(num1: number | bigint | undefined, fixed: number = 3): string {
        if (num1 === undefined) {
            return '';
        }
        const str: string = typeof num1 === 'bigint' ? ethers.formatEther(BigInt(num1)) : num1.toString();
        const num = Number(str);
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(fixed) + 'K'; // convert to K for number from > 1000 < 1 million
        } else if (num >= 1000000 && num < 1000000000) {
            return (num / 1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million
        } else if (num >= 1000000000) {
            return (num / 1000000000).toFixed(3) + 'B'; // convert to B for number from > 1 billion
        } else if (num <= 999) {
            return num.toFixed(fixed).toString(); // if value < 1000, nothing to do
        }
        return num.toString();
    }

    // parse project meta to ProjectMeta
    parseMeta(meta: string): ProjectMeta | undefined {
        let obj: Array<ProjectMetaItem> = [];
        try {
            // data:application/json;base64,
            const _meta = meta.split(',')[1];

            obj = JSON.parse(
                atob(_meta)
            );

            // iterator obj , each element is formatted [{'traid_name': 'image', 'value': 'https://xxx'}, {'traid_name': 'youtube', 'value': 'https://xxx']
            let result: ProjectMeta = {};

            obj.forEach((element: ProjectMetaItem) => {
                result[element.trait_type] = element.value;
            });

            return result;

        } catch (error) {
            console.error(error);
        }

        return undefined;
    }

    render(): React.ReactNode {
        const { project, } = this.props;
        const { market, price } = this.state;

        const symbol = process.env.REACT_APP_BASE_CURRENCY;

        return <div className="projectCard mobile-margin-top" onClick={() => {
            // document.location.href = `/detail?address=${project.address}`;
            document.location.href = `/dt?address=${project.address}`;
        }}>
            <div style={{ textAlign: 'center' }}>
                <img src={this.state.meta?.image} alt={this.props.project.name}
                    style={{
                        width: "8rem",
                        height: "8rem",
                    }}
                />
                <div style={{
                    marginTop: '0.5rem'
                }}>
                    <span role="img" aria-label="fire">🔥</span>
                    <i><span style={{
                        color: '#fe6ffe'
                    }}>{this.props.project.rate}</span></i>
                </div>
            </div>
            <div className="projectCardInfo">
                <div
                    className="projectCardTitle z4"
                >Created by
                    <img src={hashAvatar(this.props.project.creator)} alt="" style={{
                        width: '0.8rem',
                        height: '0.8rem',
                        marginRight: '0.2rem',
                        marginLeft: '0.5rem'
                    }} />
                    {
                        this.props.project.creator.substring(0, 6) + '...' + this.props.project.creator.substring(this.props.project.creator.length - 4)
                    }</div>
                <div className='z4'>
                    {project.status === 1 && price && price.current_price_usd ? <span>
                        Price: <span style={{
                            color: '#fe6ffe'
                        }}>${
                                formatTinyPrice(price?.current_price_usd ?? 0)
                            }</span><span style={{ color: price?.price_change_24h >= 0 ? '#75b798' : 'red', marginLeft: '0.6rem' }}>
                            ({price?.price_change_24h >= 0 ? '+' : '-'}{price?.price_change_24h + ''}%)
                        </span>
                    </span> : null}
                </div>
                <div className='z4'>
                    {project.status === 1 && price ? <span>
                        Market cap:  {price && project ? <span style={{ color: '#fe6ffe' }}>
                            ${this.toKMBT(price.current_price_usd * Number(project.totalSupply) / 1e18)}
                        </span> : <span>--</span>}
                    </span> : null}
                    {market && !market.started ? <span>
                        Progress: <span style={{
                            color: '#fe6ffe'
                        }}>{
                                Number(ethers.formatEther((market.balance ?? BigInt(0)) + '')).toFixed(4)
                            } {symbol}
                            
                            ({market.progress ? market.progress + '' : '-'}%)
                        </span>
                    </span> : null
                    }
                </div>
                {!market || !market.started ? <div className='z4'>
                    Participants: <span style={{
                        color: '#fe6ffe'
                    }}>{project.funders + ''}</span>
                </div> : null}
                {/* {blocknumber && blocknumber > 0 && blockSeconds && market ? <div className="hightlight">
                    {Number(market.untilBlockNumber) - blocknumber > 0 ? <span>
                        <Badge bg="info" style={{
                            fontWeight: 'normal'
                        }}>Countdown: {
                                this.toHHMM((Number(market.untilBlockNumber) - blocknumber) * blockSeconds)
                            }</Badge>
                    </span> : market.started ? <span className='z4'>
                    </span> : <Badge bg="warning" style={{ fontWeight: 'normal' }}>Ready to launch</Badge>}
                </div> : null} */}

                <div className='z3'>
                    <b>{this.props.project.name} ({this.props.project.symbol})</b>:&nbsp;
                    {decodeURIComponent(this.state.meta?.description || '')}
                </div>
            </div>
        </div>
    }
}

export default ProjectCard;