import { API } from "../config/api";

export interface Price {
    current_price_usd: number;
    price_change_1d: number;
    price_change_24h: number;
}

export async function getPrice(address: string): Promise<Price> {

    const url = `${API}/project/price/${address}`;
    const response = await fetch(url);
    const res = await response.json();

    if (res.code !== 1) {
        throw new Error(res.msg);
    } else {
        console.log("price obj = ", res.data);
        return res.data;
    }
}