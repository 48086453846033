import React from "react";

import "./TradeWidget.css";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { Chain, CHAINS, CurrentChain } from "../../config/chains";
import { ethers, Provider, TransactionResponse } from "ethers";
import { IFairLaunchTokenABI } from "../../abi/IFairLaunchToken.abi";
import { exactInputSingle, ExactInputSingleParams, exactOutputSingle, ExactOutputSingleParams, parseQuotaOptionsPath } from "../../utils/swap";
import { QUOTE_ABI } from "../../abi/IQuota.abi";

interface TradeWidgetProps {
    projectAddress: string;
    symbol?: string;
    logo?: string;
    chainId?: number;
    provider?: Provider;
    address?: string;
    blockNumber?: number;
    onSwapSuccess?: Function;
    onSwapFail?: Function;
    onConnect?: Function;
}

interface TradeWidgetState {
    direction: 'buy' | 'sell';
    currency: string;
    amount: string;
    chain: Chain;
    ethBalance: bigint;
    tokenBalance: bigint;
    slippage: bigint;
    showSlippage: boolean;

    tokenAmountCalculated: string;
}

interface QuoterOut {
    amountOut: bigint;
    sqrtPriceX96After: bigint;
    amountOutMin: bigint;
}

interface QuoterIn {
    amountIn: bigint;
    sqrtPriceX96After: bigint;
    amountInMax: bigint;
}

class TradeWidget extends React.Component<TradeWidgetProps, TradeWidgetState> {

    constructor(props: TradeWidgetProps) {
        super(props);
        this.state = {
            direction: 'buy',
            currency: CurrentChain.symbol,
            chain: CurrentChain,
            amount: '',
            slippage: BigInt(10),
            ethBalance: BigInt(0),
            tokenBalance: BigInt(0),

            tokenAmountCalculated: '',
            showSlippage: false,
        }
    }

    componentDidMount() {
        const slippage = this.getSlippageFromStorage();
        this.setState({ slippage });

        this.getETHBalance();
        this.getTokenBalance();
        this.calculate(this.state.amount);
    }

    componentDidUpdate(prevProps: TradeWidgetProps) {
        if (this.props.chainId !== prevProps.chainId) {
            this.setState({
                chain: CHAINS[this.props.chainId || 1],
                currency: CHAINS[this.props.chainId || 1].symbol,
            });
        }
        if (this.props.provider !== prevProps.provider || this.props.address !== prevProps.address) {
            this.getETHBalance();
            this.getTokenBalance();
        }

        if (this.props.blockNumber !== prevProps.blockNumber) {
            this.getETHBalance();
            this.getTokenBalance();
            this.calculate(this.state.amount);
        }
    }

    getETHBalance = async () => {
        if (this.props.provider && this.props.address) {
            const balance = await this.props.provider.getBalance(this.props.address);
            this.setState({ ethBalance: balance });
        }
    }

    getTokenBalance = async () => {
        if (this.props.provider && this.props.address) {
            const contract = new ethers.Contract(this.props.projectAddress, IFairLaunchTokenABI, this.props.provider);
            const balance = await contract.balanceOf(this.props.address);
            this.setState({ tokenBalance: balance });
        }
    }

    quoteBuyExactIn = async (amountInEther: string): Promise<QuoterOut> => {
        if (this.props.provider === undefined) {
            return {
                amountOut: BigInt(0),
                sqrtPriceX96After: BigInt(0),
                amountOutMin: BigInt(0),
            }
        }
        // symbol is currency,  use exact eth to buy the token
        const path = `${this.state.chain.uniswapV3.weth},${this.state.chain.feePool},${this.props.projectAddress}`
        const encodedPath = parseQuotaOptionsPath(path);

        const quoteContract = new ethers.Contract(this.state.chain.uniswapV3.quoteContract, QUOTE_ABI, this.props.provider);
        const amountIn = ethers.parseEther(amountInEther)
        console.log(this.state.chain.uniswapV3.quoteContract)
        const quoteRes = await quoteContract.quoteExactInput.staticCall(
            encodedPath,
            amountIn
        );
        const slip = this.getSlippageFromStorage();
        const quote: QuoterOut = {
            amountOut: quoteRes[0],
            sqrtPriceX96After: BigInt(quoteRes[1][0]),
            amountOutMin: quoteRes[0] - quoteRes[0] * BigInt(slip) / BigInt(10000)
        }
        return quote;
    }

    quotaBuyExactOut = async (amountOutEther: string): Promise<QuoterIn> => {
        if (this.props.provider === undefined) {
            return {
                amountIn: BigInt(0),
                sqrtPriceX96After: BigInt(0),
                amountInMax: BigInt(0)
            }
        }
        // symbol is currency,  use exact token to buy the eth
        const path = `${this.props.projectAddress},${this.state.chain.feePool},${this.state.chain.uniswapV3.weth}`
        const encodedPath = parseQuotaOptionsPath(path);
        const quoteContract = new ethers.Contract(this.state.chain.uniswapV3.quoteContract, QUOTE_ABI, this.props.provider);
        const amountOut = ethers.parseEther(amountOutEther)

        const quoteRes = await quoteContract.quoteExactOutput.staticCall(
            encodedPath,
            amountOut
        );
        const slip = this.getSlippageFromStorage();
        const quote: QuoterIn = {
            amountIn: quoteRes[0],
            sqrtPriceX96After: BigInt(quoteRes[1][0]),
            amountInMax: quoteRes[0] + quoteRes[0] * BigInt(slip) / BigInt(10000)
        }

        return quote;
    }

    quoteSellExactIn = async (amountInEther: string): Promise<QuoterOut> => {
        if (this.props.provider === undefined) {
            return {
                amountOut: BigInt(0),
                sqrtPriceX96After: BigInt(0),
                amountOutMin: BigInt(0)
            }
        }
        // symbol is token, specify the amount of token to sell, return the amount of eth to get
        const path = `${this.props.projectAddress},${this.state.chain.feePool},${this.state.chain.uniswapV3.weth}`
        const encodedPath = parseQuotaOptionsPath(path);
        const quoteContract = new ethers.Contract(this.state.chain.uniswapV3.quoteContract, QUOTE_ABI, this.props.provider);
        const amountIn = ethers.parseEther(amountInEther)

        const quoteRes = await quoteContract.quoteExactInput.staticCall(
            encodedPath,
            amountIn
        );

        const slip = this.getSlippageFromStorage();

        const quote: QuoterOut = {
            amountOut: quoteRes[0],
            sqrtPriceX96After: BigInt(quoteRes[1][0]),
            amountOutMin: quoteRes[0] - quoteRes[0] * BigInt(slip) / BigInt(10000)
        }

        return quote;
    }

    calculate = async (amount: string) => {
        if (!amount || amount === '' || parseFloat(amount) <= 0) {
            this.setState({ tokenAmountCalculated: '' });
            return;
        }

        const { symbol } = this.props;
        const {
            direction,
            chain,
        } = this.state;

        if (direction === 'buy') {
            // buy token
            if (this.state.currency === this.state.chain.symbol) {
                // 确定支付eth。显示最少可以换多少token
                const quote = await this.quoteBuyExactIn(amount);
                const tokenOutAmount = ethers.formatEther(quote.amountOutMin);
                this.setState({ tokenAmountCalculated: `${tokenOutAmount} ${symbol}` });
            } else {
                // 确定换多少token，显示需要支付多少eth
                const quote = await this.quotaBuyExactOut(amount);
                const ethInAmount = ethers.formatEther(quote.amountInMax);
                this.setState({ tokenAmountCalculated: `${ethInAmount} ${chain.symbol}` });
            }
        } else {
            // sell token
            // 确定支付多少token，显示可以换多少eth
            const quote = await this.quoteSellExactIn(amount);
            const ethOutAmount = ethers.formatEther(quote.amountOutMin);
            this.setState({ tokenAmountCalculated: `${ethOutAmount} ${chain.symbol}` });

        }
    }

    _doSwap = async (): Promise<void> => {
        try {
            const tx = await this.doSwap();
            this.props.onSwapSuccess && this.props.onSwapSuccess(tx);
        } catch (e) {
            console.error(JSON.stringify(e));
            console.error("e.code", e);
            this.props.onSwapFail && this.props.onSwapFail(e);
        }
    }

    doSwap = async (): Promise<TransactionResponse> => {
        const { projectAddress } = this.props;
        const { direction, amount, chain, currency } = this.state;

        if (amount === '' || parseFloat(amount) <= 0) {
            throw new Error('Invalid amount');
        }


        if (direction === 'buy') {
            // quote buy exact in or buy exact out
            if (currency === chain.symbol) {
                // quote buy exact in
                const quote = await this.quoteBuyExactIn(amount);
                const params: ExactInputSingleParams = {
                    tokenIn: chain.uniswapV3.weth,
                    tokenOut: projectAddress,
                    fee: chain.feePool,
                    deadline: BigInt(Date.now() + 1000 * 60 * 10),
                    amountIn: ethers.parseEther(amount),
                    amountOutMinimum: quote.amountOutMin,
                    sqrtPriceLimitX96: quote.sqrtPriceX96After,
                    // sqrtPriceLimitX96: BigInt(0),
                }
                // do swap
                const tx = await exactInputSingle(chain, params);
                return tx;
            } else {
                // quote buy exact out
                const quote = await this.quotaBuyExactOut(amount);
                const params: ExactOutputSingleParams = {
                    tokenIn: chain.uniswapV3.weth,
                    tokenOut: projectAddress,
                    fee: chain.feePool,
                    amountOut: ethers.parseEther(amount),
                    deadline: BigInt(Date.now() + 1000 * 60 * 10),
                    amountInMaximum: quote.amountInMax,
                    sqrtPriceLimitX96: quote.sqrtPriceX96After,
                }
                // do swap
                const tx = await exactOutputSingle(chain, params);
                return tx;
            }
        } else {
            const quote = await this.quoteSellExactIn(amount);
            const params: ExactInputSingleParams = {
                tokenIn: projectAddress,
                tokenOut: chain.uniswapV3.weth,
                deadline: BigInt(Date.now() + 1000 * 60 * 10),
                fee: chain.feePool,
                amountIn: ethers.parseEther(amount),
                amountOutMinimum: quote.amountOutMin,
                sqrtPriceLimitX96: quote.sqrtPriceX96After,
            }
            const tx = await exactInputSingle(chain, params, quote.amountOut);
            return tx;
        }
    }

    saveSlippageToStorage = () => {
        localStorage.setItem('slippage', this.state.slippage.toString());
    }

    getSlippageFromStorage = ():bigint => {
        const slippage = localStorage.getItem('slippage');
        if (slippage) {
            return BigInt(slippage);
        } else {
            // default is 50
            return BigInt(50);
        }
    }

    render() {
        const { symbol } = this.props;
        const { direction, currency, chain, tokenBalance } = this.state;
        return (
            <div className="trade-widget">
                <div className="trade-buttons">
                    <Button variant={direction === 'buy' ? 'success' : 'secondary'} onClick={(e) => {
                        this.setState({ direction: 'buy', currency: chain.symbol, amount: '0', tokenAmountCalculated: '' });
                    }}>Buy</Button>
                    <div style={{ width: '0.8rem' }}></div>
                    <Button variant={direction === 'sell' ? 'danger' : 'secondary'} onClick={(e) => {
                        this.setState({ direction: 'sell', currency: symbol ?? '', amount: '0', tokenAmountCalculated: '' });
                    }}>Sell</Button>
                </div>
                <div className="trade-buttons">
                    {direction === 'buy' ? <div className="secondBtn" onClick={() => {
                        if (currency === chain.symbol) {
                            this.setState({ currency: symbol ?? '', amount: '0', tokenAmountCalculated: '' });
                        } else {
                            this.setState({ currency: chain.symbol, amount: '0', tokenAmountCalculated: '' });
                        }
                    }}>switch to {
                            currency === chain.symbol ? symbol : chain.symbol
                        }</div> : <div></div>}
                    <div className="secondBtn" onClick={() => {
                        this.setState({ showSlippage: true });
                    }}>Set max slippage</div>
                </div>
                <div className="trade-form">
                    <InputGroup className="mb-3" style={{
                        border: 'solid 1px #fff',
                        borderRadius: '6px',
                    }}>
                        <Form.Control
                            placeholder="0.0"
                            aria-label=""
                            aria-describedby="basic-addon2"
                            value={this.state.amount}
                            type="number"
                            isValid={this.state.amount !== undefined && parseFloat(this.state.amount) > 0}
                            isInvalid={this.state.amount !== undefined && parseFloat(this.state.amount) <= 0}
                            onChange={(e) => {
                                this.setState({ amount: e.target.value });
                                this.calculate(e.target.value);
                            }}
                        />
                        <InputGroup.Text id="basic-addon2">{currency}</InputGroup.Text>
                    </InputGroup>
                    {this.state.tokenAmountCalculated && this.state.tokenAmountCalculated !== '' ? <div className="quoteDisplay">{this.state.tokenAmountCalculated}
                    </div> : null}
                </div>
                {direction === 'buy' ? currency === chain.symbol ? <div className="trade-buttons-inline">
                    <div className="secondBtn" onClick={() => {
                        this.setState({ amount: '0', tokenAmountCalculated: '' });
                    }}>reset</div>
                    <div className="secondBtn" onClick={() => {
                        this.setState({ amount: '0.05' });
                        this.calculate('0.05');

                    }}>0.05 {chain.symbol}</div>
                    <div className="secondBtn" onClick={() => {
                        this.setState({ amount: '0.25' });
                        this.calculate('0.25');
                    }}>0.25 {chain.symbol}</div>
                    <div className="secondBtn" onClick={() => {
                        this.setState({ amount: '0.5' });
                        this.calculate('0.5');
                    }}>0.5 {chain.symbol}</div>
                </div> : <div></div> : <div className="trade-buttons-inline">
                    <div className="secondBtn" onClick={() => {
                        this.setState({ amount: '0', tokenAmountCalculated: '' });
                    }}>reset</div>
                    <div className="secondBtn" onClick={() => {
                        this.setState({ amount: ethers.formatEther(tokenBalance * BigInt(25) / BigInt(100)) });
                        this.calculate(ethers.formatEther(tokenBalance * BigInt(25) / BigInt(100)));
                    }}>25%</div>
                    <div className="secondBtn" onClick={() => {
                        this.setState({ amount: ethers.formatEther(tokenBalance * BigInt(50) / BigInt(100)) });
                        this.calculate(ethers.formatEther(tokenBalance * BigInt(50) / BigInt(100)));
                    }}>50%</div>
                    <div className="secondBtn" onClick={() => {
                        this.setState({ amount: ethers.formatEther(tokenBalance * BigInt(75) / BigInt(100)) });
                        this.calculate(ethers.formatEther(tokenBalance * BigInt(75) / BigInt(100)));
                    }}>75%</div>
                    <div className="secondBtn" onClick={() => {
                        this.setState({ amount: ethers.formatEther(tokenBalance) });
                        this.calculate(ethers.formatEther(tokenBalance));
                    }}>100%</div>
                </div>}
                <div className="trade-buttons" style={{
                    marginTop: '1rem',
                }}>
                    <Button variant={
                        direction === 'buy' ? 'success' : 'danger'
                    } style={{
                        width: '100%',
                    }} onClick={() => {
                        this._doSwap()
                    }}>place trade</Button>
                </div>

                <Modal show={this.state.showSlippage} onHide={() => {
                    this.setState({ showSlippage: false });
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>slippage</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Set max slippage (%)</Form.Label>
                        <InputGroup><Form.Control
                            placeholder="0.0"
                            aria-label=""
                            aria-describedby="basic-addon2"
                            value={(Number(this.state.slippage) / 100) + ''}
                            type="number"
                            onChange={(e) => {
                                this.setState({ slippage: BigInt(e.target.value) * BigInt(100) });
                            }}
                        />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                        <div className="slippageOptions">
                            <div
                                onClick={() => {
                                    this.setState({ slippage: BigInt(10) });
                                }}
                            className={
                                this.state.slippage === BigInt(10) ? "slippageOption slippageOptionActive" : "slippageOption"
                            }>0.1%</div>
                            <div onClick={() => {
                                this.setState({ slippage: BigInt(50) });
                            }} className={
                                this.state.slippage === BigInt(50) ? "slippageOption slippageOptionActive" : "slippageOption"
                            }>0.5%</div>
                            <div onClick={() => {
                                this.setState({ slippage: BigInt(100) });
                            }} className={
                                this.state.slippage === BigInt(100) ? "slippageOption slippageOptionActive" : "slippageOption"
                            }>1%</div>
                            <div onClick={() => {
                                this.setState({ slippage: BigInt(300) });
                            }} className={
                                this.state.slippage === BigInt(300) ? "slippageOption slippageOptionActive" : "slippageOption"
                            }>3%</div>
                        </div>
                        <Form.Text>
                            This is the maximum slippage you are willing to accept. If the price changes by more than this amount, the transaction will be cancelled.
                        </Form.Text>

                        <Form.Label style={{
                            "cursor": "pointer",
                        }} onClick={() => {
                            window.open('https://docs.flashbots.net/flashbots-protect/overview', '_blank');
                        }}>How to enable front-running protection? </Form.Label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({ showSlippage: false });
                        }}>
                            Close
                        </Button>
                        <Button variant="warning" onClick={() => {
                            this.saveSlippageToStorage();
                            
                            this.setState({ showSlippage: false });
                        }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                {!this.props.provider || !this.props.address ? <div className="connectCover">
                    {/* <Button variant="info" onClick={() => {
                        if (this.props.onConnect) {
                            this.props.onConnect();
                        }
                    }}>
                        Connect Wallet
                    </Button> */}
                    
                </div> : null }
            </div>
        );
    }
}

export default TradeWidget;