import React from 'react';

import "./ProjectList.css"
import ProjectCard, { Project } from './ProjectCard';
import { Provider } from 'ethers';
import { Col, Pagination } from 'react-bootstrap';
import { Chain, CHAINS } from '../../config/chains';

interface ProjectListProps {
    projects: Array<Project>;
    total: number;
    page: number;
    size: number;
    provider?: Provider;
    onPageChange: (page: number) => void;
}

interface ProjectListState {
    blockNumber: number;
    chain?: Chain;
}

class ProjectList extends React.Component<ProjectListProps> {

    state: ProjectListState = {
        blockNumber: 0,
        chain: undefined,
    }

    componentDidMount() {
        this.init();

    }

    init = async () => {
        if (!this.props.provider) {
            return;
        }
        const blockNumber = await this.props.provider?.getBlockNumber();
        this.setState({ blockNumber: blockNumber! });

        const network = await this.props.provider?.getNetwork();
        const chain = CHAINS[Number(network.chainId)];
        this.setState({ chain: chain });

        const that = this;
        this.props.provider.on('block', async (blockNumber: number) => {
            that.setState({
                blockNumber: blockNumber,
            });
        })
    }

    render() {
        return (<div>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
            }}>
                {this.props.projects.map((project: Project, index: number) => {
                    return <Col sm={12} md={4} lg={4} key={
                        index
                    }>
                        <ProjectCard project={project} blockSeconds={this.state.chain?.blockSeconds} blocknumber={this.state.blockNumber} provider={this.props.provider}></ProjectCard>
                    </Col>
                })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                <Pagination>
                    <Pagination.Prev disabled={this.props.page === 0} onClick={() => {
                        if (this.props.page === 0) {
                            return;
                        }
                        this.props.onPageChange(this.props.page - 1);
                    }} />
                    <Pagination.Item disabled>{this.props.page + 1}</Pagination.Item>
                    <Pagination.Next disabled={this.props.total <= (this.props.page + 1) * this.props.size} onClick={() => {
                        if (this.props.total <= (this.props.page + 1) * this.props.size) {
                            return;
                        }
                        this.props.onPageChange(this.props.page + 1);
                    }} />
                </Pagination>
            </div>
        </div>)
    }
}

export default ProjectList;