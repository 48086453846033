import React from "react";
import Header from "../components/header/Header";
import { CHAINS, CurrentChain } from "../config/chains";
import { connect, isInWeb3Browser, onAccountChange, onChainSwitch, switchNetwork, toEIP55, tryConnectSilent, watchTxRecp } from "../utils/dapp";
import { Contract, ethers, Provider, TransactionReceipt } from "ethers";
import { Project, ProjectMeta, ProjectMetaItem } from "../components/project/ProjectCard";
import { API, QR } from "../config/api";
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";

import "./DetailPage3.css";
import { doClaimETH, doFund, doMint, doRefund, doStart } from "../utils/fairlaunch";
import Footer from "../components/footer/Footer";

import CopyIcon from '../assets/icon/copy.svg';
import QrCodeIcon from '../assets/icon/qr.svg';
import InformationIcon from '../assets/icon/information.png';
import ErrorIcon from '../assets/icon/error.png';
import SuccessIcon from '../assets/icon/success.png';
import WaitingIcon from '../assets/icon/waiting.png';
import copy from "copy-to-clipboard";

import { hashAvatar } from "../utils/avatar";
import ShareImage from "../assets/image/share.png";

import UniswapLogo from '../assets/logo/uniswap.svg';
// import PancakeLogo from '../assets/logo/pancake.png';
import TrendIcon from '../assets/icon/trend.png';
import { formatTinyPrice } from "../utils/format";

import EmptyImage from '../assets/image/empty.png';
import OpenInWeb3Browser from "../components/web3opener/OpenInWeb3Browser";

import SecureImage from '../assets/icon/secure.png';
import { getPrice, Price } from "../utils/project";
import { FairLaunchLimitAmountToken } from "../abi/FairLaunchLimitAmountToken.abi";


interface DetailPage3Props {
    projectAddress: string;
}

interface DetailPage3State {
    chain?: any;
    address?: string;
    provider?: Provider;
    project?: Project;
    meta?: ProjectMeta;

    started?: boolean;
    minted: number | bigint;

    totalEthers: bigint;
    amountInput: string;

    showAmountInput: boolean;
    showAlert: boolean;
    showConfirm: boolean;
    onConfirm?: Function;
    confirmMessage: string;
    txHash: string | null;
    timer: any;
    alertMessage: string;
    showTxResult: boolean;
    canStart: boolean;

    fundEvents: Array<FundEvent>;

    txReceipt?: TransactionReceipt | null;
    projectOwner: string;

    bannerProject?: Project;
    bannerFundEvents: Array<FundEvent>;
    bannerFundEvent?: FundEvent;
    animation: boolean;

    currentIndex: number;

    showQR: boolean;

    price?: Price | null;

    showShare: boolean;
    showWeb3Opener: boolean;
    // [propName: string]: any;

    shares: number | bigint;
    pricePerShare: number | bigint;
    maxUnitsOfEachAddress: number | bigint;
    amountPerShare: number | bigint;
    progress: bigint | number;
}

interface FundEvent {
    hash: string;
    from: string;
    ethAmount: string;
    blockNumber: number;
    timestamp: number;
    project: Project;
}

class DetailPage3 extends React.Component<DetailPage3Props, DetailPage3State> {

    constructor(props: DetailPage3Props) {
        super(props);
        this.state = {
            chain: undefined,
            address: undefined,
            provider: undefined,
            project: undefined,
            meta: undefined,

            started: false,
            totalEthers: BigInt(0),
            canStart: false,

            showAmountInput: false,
            showAlert: false,
            showConfirm: false,
            onConfirm: undefined,
            confirmMessage: '',
            txHash: null,
            timer: null,

            amountInput: '0.01',
            alertMessage: '',

            showTxResult: false,
            txReceipt: undefined,
            projectOwner: '',

            fundEvents: [],
            bannerProject: undefined,
            bannerFundEvents: [],
            bannerFundEvent: undefined,

            animation: true,
            currentIndex: 0,

            showQR: false,

            price: undefined,

            showShare: false,
            showWeb3Opener: false,

            shares: 0,
            pricePerShare: 0,
            maxUnitsOfEachAddress: 0,
            amountPerShare: 0,
            progress: 0,
            minted: 0,
        };
    }

    componentDidMount() {
        this.initSilent();
        this.loadProject();
        this.loadFundEvents();
        this.loadPrice();

        setInterval(() => {
            this.setState({ animation: !this.state.animation });
            if (this.state.bannerFundEvents.length > 0) {
                const nextIndex = this.state.currentIndex + 1 >= this.state.bannerFundEvents.length ? 0 : this.state.currentIndex + 1;
                this.setState({
                    bannerFundEvent: this.state.bannerFundEvents[nextIndex],
                    currentIndex: nextIndex,
                });
            }
        }, 1000);
    }

    loadProject = async () => {
        // fetch 
        const url = `${API}/project/detail/${this.props.projectAddress}`;
        const res = await fetch(url);
        const data = await res.json();
        if (data.code === 1) {
            this.setState({
                project: data.data,
                meta: this.parseMeta(data.data.meta)
            });
        }
    }

    loadFundEvents = async () => {
        const url = `${API}/event/fundEvent/project/${this.props.projectAddress}`;
        const res = await fetch(url);
        const data = await res.json();
        if (data.code === 1) {
            this.setState({
                fundEvents: data.data.content ? data.data.content : data.data ? data.data : []
            });
        }
    }

    init = async () => {
        // try connect with popup
        if (!isInWeb3Browser()) {
            console.error('not in web3 browser');
            this.setState({
                showWeb3Opener: true,
            });
            return;
        }

        const res = await connect();
        if (res) {
            const chain = CHAINS[Number(res.chainId)];
            if (!chain || chain.chainId !== CurrentChain.chainId) {
                console.error('chain not supported', res.chainId);
                // this.alert('Chain not supported');
                switchNetwork(CurrentChain, () => {
                    this.init()
                });
                return;
            }

            this.setState({
                provider: res.provider,
                chain: chain,
                address: res.address,
            });
            this.watchChange();
        }
    }

    initSilent = async () => {
        this.loadProjectBaseData();
        if (!isInWeb3Browser()) {
            console.error('not in web3 browser');
            return;
        }
        // try silent connect first
        try {
            const res = await tryConnectSilent();
            if (res) {

                const chain = CHAINS[Number(res.chainId)];
                if (!chain) {
                    console.error('chain not supported', res.chainId);
                    return;
                }

                this.setState({
                    provider: res.provider,
                    chain: chain
                });

                if (res.address) {
                    this.setState({
                        address: res.address,
                    });
                }

                this.watchChange();
                this.loadProjectStatus(res.provider);
            } else {
                this.loadProjectBaseData();
            }

        } catch (e) {
            // this.loadProjectBaseData();
            console.error(e);
        }
    };

    loadProjectBaseData = async () => {
        // fetch from rest if not in web3 browser
        const response = await fetch(`${API}/project/rpc?address=${this.props.projectAddress}`);
        const data = await response.json();
        if (data.code === 1) {
            // const info = JSON.parse(data.data);
            const info = typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
            this.setState({
                totalEthers: BigInt(info.totalEthers),
                started: info.started,
            });
        }
    }

    loadProjectStatus = async (provider: Provider) => {

        if (!this.state.chain || this.state.chain.chainId !== CurrentChain.chainId) {
            return;
        }

        const contract = new Contract(this.props.projectAddress, FairLaunchLimitAmountToken, provider);
        // started
        const started = await contract.started();
        // total supply
        const totalSupply = await contract.totalSupply();

        // balance of contract
        const totalEthers = await provider.getBalance(this.props.projectAddress);
        const minted: bigint = await contract.minted();
        const price: bigint = await contract.price();
        const amountPerShare: bigint = await contract.amountPerShare();
        const canStart: boolean = minted === totalSupply * BigInt(60) / BigInt(100);

        const shares: bigint = totalSupply * BigInt(60) / BigInt(100) / amountPerShare;
        //maxUnitsOfEachAddress
        const maxUnitsOfEachAddress: bigint = await contract.maxUnitsOfEachAddress();

        // projectOwner
        const projectOwner = await contract.projectOwner();

        // progress
        const progress: bigint = minted * BigInt(100) / amountPerShare / shares;

        this.setState({
            started: started,
            minted: minted,
            totalEthers: totalEthers,
            projectOwner: projectOwner,
            shares: shares,
            pricePerShare: price,
            maxUnitsOfEachAddress: maxUnitsOfEachAddress,
            amountPerShare: amountPerShare,
            canStart: canStart,
            progress: progress,
        });
    }

    loadPrice = async () => {
        const priceObj = await getPrice(this.props.projectAddress);
        console.log('price obj = ', priceObj);
        this.setState({
            price: priceObj
        });
    }

    parseMeta(meta: string): ProjectMeta | undefined {
        let obj: Array<ProjectMetaItem> = [];
        try {
            // data:application/json;base64,
            const _meta = meta.split(',')[1];
            obj = JSON.parse(
                atob(_meta)
            );
            let result: ProjectMeta = {};
            obj.forEach((element: ProjectMetaItem) => {
                result[element.trait_type] = element.value;
            });
            return result;
        } catch (error) {
            console.error(error);
        }
        return undefined;
    }

    watchChange = async (): Promise<void> => {
        onAccountChange((accounts: any) => {
            this.setState({
                address: toEIP55(accounts[0])
            })
        })

        onChainSwitch((chainId: number | string) => {
            console.log('chainId:', chainId)
            this.initSilent();
        })
    }

    alert = (message: string) => {
        this.setState({
            showAlert: true,
            alertMessage: message
        });
    }

    formatDate(timestamp: number): string {
        // hh:mm:ss
        const date = new Date(timestamp);
        // to HH:mm:ss
        return date.toTimeString().substring(0, 8);
    }


    doFund = async () => {
        const tx = await doFund(this.props.projectAddress, ethers.parseEther(this.state.amountInput))

        this.setState({
            showAmountInput: false,
            showTxResult: true,
            txHash: tx?.hash ?? null
        });
        const that = this
        if (tx && tx.hash && this.state.provider) {
            watchTxRecp(this.state.timer, tx.hash, this.state.provider, (recp: any) => {
                that.setState({
                    timer: null,
                    txReceipt: recp,
                });
            })
        }
    }

    doRefund = async () => {
        const that = this

        that.setState({
            showConfirm: true,
            confirmMessage: 'Are you sure to refund?',
            onConfirm: async () => {
                const tx = await doRefund(that.props.projectAddress);
                that.setState({
                    showConfirm: false,
                    onConfirm: undefined,
                    txHash: tx?.hash || null,
                    showTxResult: true
                    ,
                });

                if (tx && tx.hash && that.state.provider) {
                    watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                        that.setState({
                            timer: null,
                            txReceipt: recp
                        });
                    })
                }
            }
        });
    }

    doLaunch = async () => {
        const that = this

        that.setState({
            showConfirm: true,
            confirmMessage: 'Are you sure to launch?',
            onConfirm: async () => {
                try {
                    const tx = await doStart(that.props.projectAddress);
                    that.setState({
                        showConfirm: false,
                        onConfirm: undefined,
                        txHash: tx?.hash || null,
                        showTxResult: true
                        ,
                    });

                    if (tx && tx.hash && that.state.provider) {
                        watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                            that.setState({
                                timer: null,
                                txReceipt: recp
                            });
                        })
                    }
                } catch (e: any) {
                    console.error(JSON.stringify(e));
                    that.alert(e.message);
                }
            }
        });
    }

    doClaim = async () => {
        const that = this

        that.setState({
            showConfirm: true,
            confirmMessage: 'Are you sure to claim?',
            onConfirm: async () => {
                const tx = await doMint(that.props.projectAddress);
                that.setState({
                    showConfirm: false,
                    onConfirm: undefined,
                    txHash: tx?.hash || null,
                    showTxResult: true
                    ,
                });

                if (tx && tx.hash && that.state.provider) {
                    watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                        that.setState({
                            timer: null,
                            txReceipt: recp
                        });
                    })
                }
            }
        });
    }

    doClaimETH = async () => {
        const that = this
        const symbol = process.env.REACT_APP_BASE_CURRENCY

        that.setState({
            showConfirm: true,
            confirmMessage: 'Are you sure to claim extra' + symbol + ' ?',
            onConfirm: async () => {
                const tx = await doClaimETH(that.props.projectAddress);
                that.setState({
                    showConfirm: false,
                    onConfirm: undefined,
                    txHash: tx?.hash || null,
                    showTxResult: true
                    ,
                });

                if (tx && tx.hash && that.state.provider) {
                    watchTxRecp(that.state.timer, tx.hash, that.state.provider, (recp: any) => {
                        that.setState({
                            timer: null,
                            txReceipt: recp
                        });
                    })
                }
            }
        });
    }

    render() {
        const { project, meta, totalEthers, started, price } = this.state;
        const symbol = process.env.REACT_APP_BASE_CURRENCY
        return (
            <div style={{
                overflowX: 'hidden'
            }}>
                <Header showMessage={false} chain={this.state.chain} onConnectClick={() => {
                    this.init();
                }} address={this.state.address ?? ""} />
                <div className="detail2Body">
                    <Row>
                        <Col sm={12} md={8} lg={8}>
                            <div>
                                <div className="detail2Project">
                                    <div className="detail2ProjecImage">
                                        <img src={meta?.image} alt="icon" />

                                        <div>
                                            <span role="img" aria-label="fire">🔥</span> {project?.rate}
                                        </div>
                                    </div>
                                    <div className="detail2ProjectInfo z4">
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.3rem',
                                        }}>
                                            <div>Created by </div>
                                            <img src={hashAvatar(this.state.project?.creator ?? "0x00000000000000000000000000000000")} alt="" style={{
                                                width: '0.8rem',
                                                height: '0.8rem',
                                                marginRight: '0.2rem',
                                                marginLeft: '0.5rem'
                                            }} />
                                            <div>
                                                {project?.creator.substring(0, 6) + '...' + project?.creator.substring(project?.creator.length - 4)}
                                            </div>

                                        </div>
                                        <div>Mint progress: <span style={{
                                            color: '#fe6ffe'
                                        }}>{
                                                Number(ethers.formatEther(totalEthers)).toFixed(4)
                                            } {symbol}
                                            {/* ({
                                                project ? (totalEthers * BigInt(100) / BigInt(project.softCap)).toString() : ''
                                            }%) */}
                                            ({this.state.progress.toString()}%)
                                        </span>
                                        </div>
                                        <div>Mint price: <span style={{
                                            color: '#fe6ffe'
                                        }}>{
                                                // Number(ethers.formatEther(totalEthers)).toFixed(4)
                                                this.state.pricePerShare.toString()
                                            } {symbol}
                                            {/* ({
                                                project ? (totalEthers * BigInt(100) / BigInt(project.softCap)).toString() : ''
                                            }%) */}
                                        </span>
                                        </div>
                                        <div>Participants: <span style={{ color: '#fe6ffe' }}>{project?.funders}</span></div>

                                        <div className="z3">
                                            <b>{project?.name} ({project?.symbol})</b>: {decodeURIComponent(meta?.description ?? '')}
                                        </div>
                                        <div className="detail2ProjectSocial z4">
                                            {meta?.website ? <div onClick={() => {
                                                window.open(meta?.website, '_blank');
                                            }}
                                            >[website]</div> : null}
                                            {meta?.x ? <div onClick={() => {
                                                window.open(meta?.x, '_blank');
                                            }}>[twitter]</div> : null}
                                            {/* telegram */}
                                            {meta?.telegram ? <div onClick={() => {
                                                window.open(meta?.telegram, '_blank');
                                            }}>[telegram]</div> : null}
                                            {/* discord */}
                                            {meta?.discord ? <div onClick={() => {
                                                window.open(meta?.discord, '_blank');
                                            }}>[discord]</div> : null}
                                            {/* github */}
                                            {meta?.github ? <div onClick={() => {
                                                window.open(meta?.github, '_blank');
                                            }}>[github]</div> : null}
                                            {/* gitbook */}
                                            {meta?.gitbook ? <div onClick={() => {
                                                window.open(meta?.gitbook, '_blank');
                                            }}>[gitbook]</div> : null}
                                            {/* youtube */}
                                            {meta?.youtube ? <div onClick={() => {
                                                window.open(meta?.youtube, '_blank');
                                            }}>[youtube]</div> : null}

                                            <div style={{
                                                alignContent: 'center',
                                                display: 'flex',
                                                gap: '0.2rem',
                                                alignItems: 'center',
                                            }} onClick={() => {
                                                this.setState({
                                                    showShare: true
                                                });
                                            }}> [
                                                <img src={ShareImage} alt="share" style={{
                                                    width: '0.75rem',
                                                    height: '0.75rem',
                                                }} />
                                                Share]</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end of information */}
                            {started ? <div className="detail2marketInfo">
                                <div>
                                    <div>
                                        <img src={UniswapLogo} alt="uniswap" />
                                        <div>Listed on Uniswap</div>
                                    </div>
                                    <div>Price:  <span style={{ color: '#fe6ffe' }}>${formatTinyPrice(price?.current_price_usd ?? 0)}</span> </div>
                                    <div>Market Cap:  {price && project ? <span style={{ color: '#fe6ffe' }}>
                                        ${this.toKMBT(price.current_price_usd * Number(project.totalSupply) / 1e18)}
                                    </span> : <span>--</span>} </div>
                                </div>
                                <div>
                                    <img src={TrendIcon} alt="trade" className="pointer" onClick={() => {
                                        // document.location.href = `https://dexscreener.com/base/${this.props.projectAddress}`;
                                        // if is mobile system
                                        if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
                                            document.location.href = `https://dexscreener.com/ethereum/${this.props.projectAddress}`;
                                        } else {
                                            window.open(`https://dexscreener.com/ethereum/${this.props.projectAddress}`, '_blank');
                                        }
                                    }} />
                                </div>
                            </div> : null}
                            <div className="detail2hightlight z4">
                                <img src={SecureImage} alt="secure" style={{
                                    width: '1rem',
                                    height: '1rem',
                                    marginRight: '0.5rem',
                                }} />
                                https://epump.fun/{project?.shortName}
                            </div>
                            {/* end of hightlight */}
                            <div>
                                <div className="detail2info1">
                                    <div className="z2">FairMint</div>
                                    <div>
                                        {this.state.progress + ''}% Progress
                                    </div>
                                    <div className="z4 pointer" onClick={() => {
                                        document.location.href = process.env.REACT_APP_FAIR_MINT_URL ?? 'HTTPS://rocketmeme.gitbook.io' //"https://rocketmeme.gitbook.io/bsc/fairmint"
                                    }} >[How to participate?]</div>
                                </div>

                                <div>
                                    <div className="detail2info1 z3" style={{ marginTop: '1rem' }}>Send {ethers.formatEther((this.state.pricePerShare ?? 0).toString())} {symbol} to {project?.symbol}'s CA to participate in FairMint. Eath wallet is limited to {
                                        (this.state.maxUnitsOfEachAddress ?? 0).toString()
                                    } mint </div>
                                    <div className="detail2hightlight" style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexWrap: 'wrap'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            fontSize: '1rem',
                                            color: '#fe6ffe',
                                        }}>
                                            <img src={CurrentChain.icon} alt="icon" style={{
                                                width: '1rem',
                                                height: '1rem',
                                                marginRight: '0.5rem',

                                            }} />
                                            <div style={{
                                                fontSize: '0.875rem',
                                                color: "#fe6ffe"
                                            }}>{project?.symbol}'s CA: {project?.address} </div>
                                        </div>
                                        <div>
                                            {/* qr  */}
                                            <img src={QrCodeIcon} alt="qr" style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                                cursor: 'pointer',
                                                marginRight: '0.8rem',
                                            }} onClick={() => {
                                                this.setState({
                                                    showQR: true
                                                });
                                            }} />
                                            {/* copy  */}
                                            <img src={CopyIcon} alt="copy" style={{
                                                width: '1.2rem',
                                                height: '1.2rem',
                                                cursor: 'pointer',
                                            }} onClick={() => {
                                                copy(project?.address ?? "");
                                                this.alert('Copied to clipboard');
                                            }} />
                                        </div>
                                    </div>

                                </div>
                                

                                {/* infomation */}
                                <div className="detail2info2">
                                    <div className="detail2info2item">
                                        <div>Name:</div>
                                        <div>
                                            {/* <img src={CurrentChain.icon} alt="icon" style={{
                                                width: '1rem',
                                                height: '1rem',
                                                marginRight: '0.5rem',
                                            }} /> */}
                                            ${project?.symbol} ({project?.name} )
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>URL:</div>
                                        <div>
                                            {/* <img src={CurrentChain.icon} alt="icon" style={{
                                                width: '1rem',
                                                height: '1rem',
                                                marginRight: '0.5rem',
                                            }} /> */}
                                            https://epump.fun/{project?.shortName}
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>Chain:</div>
                                        <div>
                                            {/* <img src={CurrentChain.icon} alt="icon" style={{
                                                width: '1rem',
                                                height: '1rem',
                                                marginRight: '0.5rem',
                                            }} /> */}
                                            {CurrentChain.name}
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>Supply:</div>
                                        <div>
                                            {/* to 000,000,000,000 format */}
                                            {Number(ethers.formatEther(BigInt(project?.totalSupply ?? 0))).toLocaleString() + ' ' + project?.symbol}
                                            (60% FairMint, 40% LP)
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>Initial LP:</div>
                                        <div>
                                            {/* softCap / totalSupply / 2 */}
                                            {Number(ethers.formatEther(BigInt(project?.totalSupply ?? 0) * BigInt(40) / BigInt(100))).toLocaleString() + ' ' + project?.symbol} / {Number(ethers.formatEther(BigInt(project?.softCap ?? 0))) + ' ' + symbol}
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>Creator:</div>
                                        <div>
                                            {project?.creator.substring(0, 10) + '...' + project?.creator.substring(project?.creator.length - 8)}
                                        </div>
                                    </div>
                                    <div className="detail2info2item">
                                        <div>Creation time:</div>
                                        <div>
                                            {new Date(project?.createdAt).toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={4} md={4} style={{
                            paddingLeft: '1.6rem',
                            paddingRight: '1.6rem',
                        }}>
                            <div className="only-mobile" style={{ height: '1rem' }}></div>

                            <Card style={{ backgroundColor: 'rgba(46,48,58,1)', border: 'none' }}>
                                <Card.Body>
                                    {!started ? <Card.Text className="z3">You can participate in FairMint by sending {symbol}  to {project?.symbol}'s CA via your wallet, or by clicking the button below. </Card.Text> : null}
                                    {started || (!started && this.state.canStart) ? <Card.Text className="z3">FairMint has ended.</Card.Text> : null}



                                    <div className="d-grid gap-2 btnMargin">
                                        {!this.state.started && !this.state.canStart ? <Button variant="warning"
                                            onClick={() => {
                                                this.setState({
                                                    showAmountInput: true
                                                });
                                            }}
                                        >
                                            Mint
                                        </Button> : null}
                                        {!this.state.started ? <Button variant="outline-success" style={{
                                            color: '#fff',
                                            border: '1px solid #97C21C',
                                        }} onClick={() => {
                                            this.doRefund()
                                        }}>
                                            Refund
                                        </Button> : null}
                                        {!this.state.started && this.state.canStart ? <Button variant="warning" onClick={() => {
                                            this.doLaunch()
                                        }} >
                                            Launch
                                        </Button> : null}
                                        {!this.state.address ? <Button variant="info" onClick={() => {
                                            this.init();
                                        }}>
                                            Connect
                                        </Button> : null}
                                    </div>
                                </Card.Body>
                            </Card>
                            {/* recently fund event */}
                            <Card style={{ backgroundColor: 'rgba(46,48,58,1)', border: 'none', marginTop: "1rem" }}>
                                <Card.Body>
                                    <div className="z2" style={{
                                        color: '#fe6ffe',
                                        marginBottom: '0.5rem',
                                    }}>FairMint Activity</div>
                                    {/* <span>0x0000...0000</span> sent <span>0.1</span> ethers */}
                                    {this.state.fundEvents.length > 0 ? <div>
                                        {
                                            this.state.fundEvents.map((event: FundEvent, index: number) => {
                                                return <div key={index} className="recentlyLogsItem z3" onClick={() => {
                                                    window.open(this.state.chain?.blockExplorer + '/tx/' + event.hash, '_blank');
                                                }}>
                                                    <div>{event.from.substring(0, 10) + '...' + event.from.substring(event.from.length - 8)}</div>  <div>{Number(ethers.formatEther(event.ethAmount + ''))} {symbol}</div>
                                                </div>
                                            })
                                        }
                                    </div> :
                                        <div>
                                            <img src={EmptyImage} alt="empty" style={{
                                                width: '10rem',
                                                height: '10rem',
                                                margin: '3rem auto',
                                                display: 'block',
                                            }} />
                                        </div>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div style={{
                    height: '4rem'
                }}></div>
                <Footer />
                {/* show qr modal */}
                <Modal show={this.state.showQR} onHide={() => {
                    this.setState({
                        showQR: false
                    });
                }}>
                    <Modal.Body>
                        <div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <div style={{
                                    fontSize: '1.2rem',
                                    color: '#fe6ffe',
                                    marginBottom: '1rem',
                                }}>{project?.symbol}'s CA QR code</div>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <img src={QR + '?code=' + project?.address} alt="qr" />
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.8rem',
                            color: '#fe6ffe',
                            marginTop: '1rem',
                        }}>
                            {project?.address}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showQR: false
                            });
                        }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* amount input modal */}
                <Modal show={this.state.showAmountInput} onHide={() => {
                    this.setState({
                        showAmountInput: false
                    });
                }}>
                    <Modal.Body>
                        <div style={{
                        }}>
                            <Form.Label htmlFor="basic-url">
                                Enter the amount of {symbol} you want to pay for <span style={{ color: '#fe6ffe' }}>${project?.symbol}</span>
                            </Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder={symbol + " amount"}
                                    aria-label="ethers"
                                    aria-describedby="basic-addon2"
                                    type="number"
                                    value={this.state.amountInput + ""}
                                    max="10"
                                    min="0.0001"
                                    isInvalid={Number(this.state.amountInput) < 0.0001 || Number(this.state.amountInput) > 10}
                                    isValid={Number(this.state.amountInput) > 0.0001 && Number(this.state.amountInput) <= 10}
                                    onChange={(e) => {
                                        this.setState({
                                            amountInput: e.target.value
                                        });
                                    }}
                                />
                                <InputGroup.Text id="basic-addon2">{symbol}</InputGroup.Text>
                            </InputGroup>
                            <Form.Text>
                                The maximum FairMint amount per wallet is 10ETH.
                            </Form.Text>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showAmountInput: false
                            });
                        }}>
                            Close
                        </Button>
                        <Button variant="warning" onClick={async () => {
                            this.doFund()
                        }}>
                            Send ${symbol} for ${project?.symbol}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* show alert */}
                <Modal centered show={this.state.showAlert}>
                    <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
                        <div><img src={InformationIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /></div>
                        <div>{this.state.alertMessage}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showAlert: false
                            });
                        }}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
                {/* show confirm */}
                <Modal centered show={this.state.showConfirm}>
                    <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
                        <div><img src={InformationIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /></div>
                        <div>{this.state.confirmMessage}</div>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showConfirm: false,
                                onConfirm: undefined
                            });
                        }}>
                            Cancel
                        </Button>
                        <Button variant="warning" onClick={() => {
                            if (this.state.onConfirm !== undefined) {
                                this.state.onConfirm();
                            }
                        }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* show tx result */}
                <Modal centered show={this.state.showTxResult}>
                    <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
                        <div>
                            {!this.state.txReceipt ? <img src={WaitingIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
                            {this.state.txReceipt && this.state.txReceipt.status === 1 ? <img src={SuccessIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
                            {this.state.txReceipt && this.state.txReceipt.status !== 1 ? <img src={ErrorIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /> : null}
                        </div>
                        {this.state.txHash ? <div>
                            Transaction hash: <a href={
                                this.state.chain?.blockExplorer + '/tx/' + this.state.txHash
                            } target="_blank" rel="noreferrer"> {this.state.txHash.substring(0, 10) + '...' + this.state.txHash.substring(this.state.txHash.length - 8)}</a>
                        </div> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showTxResult: false
                            });
                        }}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* show share, share is a dialog with project icon\ project name and symbol, and a url of this page with copy icon */}
                <Modal centered show={this.state.showShare}>
                    <Modal.Body>
                        {/* <div>
                            <img src={ShareIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} />
                        </div> */}
                        <div>Share it with your friends</div>
                        <div style={{
                            marginTop: '2rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}>
                            <img src={meta?.image ?? ''} alt="icon" style={{
                                width: '1rem',
                                height: '1rem',
                                marginRight: '0.5rem',
                                borderRadius: '50%',
                            }} />
                            {project?.name} ({project?.symbol})
                        </div>
                        <div>
                            <div className="z4" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '0.5rem',
                                marginTop: '2rem',
                                padding: '1rem',
                                backgroundColor: 'rgb(46, 48, 58)',
                                borderRadius: '0.5rem',
                            }}>
                                <div>${project?.symbol} has been launched on Epumpfun, {project?.rate} hot points now! {project && project.shortName ? ('https://epump.fun/' + project?.shortName) : window.location.href}</div>
                                <img src={CopyIcon} alt="copy" style={{
                                    width: '1.2rem',
                                    height: '1.2rem',
                                    cursor: 'pointer',
                                }} onClick={() => {
                                    copy(`$${project?.symbol} has been launched on Epumpfun, now ${project?.rate} hot. ${project && project.shortName ? ('https://epump.fun/' + project?.shortName) : window.location.href}`);
                                    this.alert('Copied to clipboard');
                                }} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showShare: false
                            });
                        }}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
                <OpenInWeb3Browser show={this.state.showWeb3Opener} onCloseClick={() => {
                    this.setState({
                        showWeb3Opener: false,
                    });
                }} />
            </div>
        );
    }

    toKMBT(num1: number | bigint | undefined, fixed: number = 3): string {
        if (num1 === undefined) {
            return '';
        }
        const str: string = typeof num1 === 'bigint' ? ethers.formatEther(BigInt(num1)) : num1.toString();
        const num = Number(str);
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(fixed) + 'K'; // convert to K for number from > 1000 < 1 million
        } else if (num >= 1000000 && num < 1000000000) {
            return (num / 1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million
        } else if (num >= 1000000000) {
            return (num / 1000000000).toFixed(3) + 'B'; // convert to B for number from > 1 billion
        } else if (num <= 999) {
            return num.toFixed(fixed).toString(); // if value < 1000, nothing to do
        }
        return num.toString();
    }
}

export default DetailPage3;