import { Contract,  TransactionResponse } from "ethers";
import { connect } from "./dapp";
import { IFairLaunchTokenABI } from "../abi/IFairLaunchToken.abi";

export async function doFund(projectAddress: string, amount: bigint):Promise<TransactionResponse | void> {

    // amount must be greater than 0.0001 eth
    if (amount < BigInt(100000000000000)) {
        throw new Error("Amount must be greater than 0.0001 eth");
    }

    const conn = await connect();
    if (!conn) {
        throw new Error("No provider");
    }
    const provider = conn.provider;
    const signer = await provider.getSigner();
    const contract = new Contract(projectAddress, IFairLaunchTokenABI, signer);

    // is started
    const isStarted = await contract.started();
    if (isStarted) {
        throw new Error("Token is launched");
    }

    const canStart = await contract.canStart(); 
    if (canStart) {
        throw new Error("Funding has ended");
    }

    // send ethers to project address
    const tx = await signer.sendTransaction({
        to: projectAddress,
        value: amount,
    });
    return tx;
}


// do refund - send 0.0002 eth to project address
export async function doRefund(projectAddress: string):Promise<TransactionResponse | void> {
    const conn = await connect();
    if (!conn) {
        throw new Error("No provider");
    }
    const provider = conn.provider;
    const signer = await provider.getSigner();
    const contract = new Contract(projectAddress, IFairLaunchTokenABI, signer);

    // is started
    const isStarted = await contract.started();
    if (isStarted) {
        throw new Error("Token is launched");
    }

    // send ethers to project address
    const tx = await signer.sendTransaction({
        to: projectAddress,
        value: BigInt(200000000000000),
    });
    return tx;
}

// do start - start the token 0.0005 eth
export async function doStart(projectAddress: string):Promise<TransactionResponse | void> {
    const conn = await connect();
    if (!conn) {
        throw new Error("No provider");
    }
    const provider = conn.provider;
    const signer = await provider.getSigner();
    const contract = new Contract(projectAddress, IFairLaunchTokenABI, signer);

    // is started
    const isStarted = await contract.started();
    if (isStarted) {
        throw new Error("Token is launched already");
    }

    // can start
    const canStart = await contract.canStart();
    if (!canStart) {
        throw new Error("Funding has not ended yet");
    }

    // send ethers to project address
    const tx = await signer.sendTransaction({
        to: projectAddress,
        value: BigInt(500000000000000),
    });
    return tx;
}

// do mint - mint token 0.0001 eth
export async function doMint(projectAddress: string):Promise<TransactionResponse | void> {
    const conn = await connect();
    if (!conn) {
        throw new Error("No provider");
    }
    const provider = conn.provider;
    const signer = await provider.getSigner();
    const contract = new Contract(projectAddress, IFairLaunchTokenABI, signer);

    // is started
    const isStarted = await contract.started();
    if (!isStarted) {
        throw new Error("Token is not launched yet");
    }
    // minted
    const minted = await contract.minted(signer.address);
    if (minted) {
        throw new Error("Minted already");
    }

    // send ethers to project address
    const tx = await signer.sendTransaction({
        to: projectAddress,
        value: BigInt(100000000000000),
    });
    return tx;
}

// do claim ex eth 0.0002 after started
export async function doClaimETH(projectAddress: string):Promise<TransactionResponse | void> {
    const conn = await connect();
    if (!conn) {
        throw new Error("No provider");
    }
    const provider = conn.provider;
    const signer = await provider.getSigner();
    const contract = new Contract(projectAddress, IFairLaunchTokenABI, signer);

    // is started
    const isStarted = await contract.started();
    if (!isStarted) {
        throw new Error("Token is not launched yet");
    }

    // is claimed
    const exeth = await contract.getExtraETH(signer.address);
    if (exeth <= BigInt(0)) {
        throw new Error("Claimed already");
    }

    // send ethers to project address
    const tx = await signer.sendTransaction({
        to: projectAddress,
        value: BigInt(200000000000000),
    });
    return tx;
}



// do claim ex eth 0.0002 after started
// export async function doClaim(projectAddress: string):Promise<TransactionResponse | void> {
//     const conn = await connect();
//     if (!conn) {
//         throw new Error("No provider");
//     }
//     const provider = conn.provider;
//     const signer = await provider.getSigner();
//     const contract = new Contract(projectAddress, IFairLaunchTokenABI, signer);

//     // is started
//     const isStarted = await contract.started();
//     if (!isStarted) {
//         throw new Error("Token is not launched yet");
//     }

//     // is claimed
//     const isClaimed = await contract.claimed();
//     if (isClaimed) {
//         throw new Error("Claimed already");
//     }

//     // send ethers to project address
//     const tx = await signer.sendTransaction({
//         to: projectAddress,
//         value: BigInt(200000000000000),
//     });
//     return tx;
// }