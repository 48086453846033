// export const API:string = "http://localhost:8080"
// export const QR:string = "http://localhost:8080/qr"

// export const API:string = "https://test.api.rocket.meme"
// export const QR:string = "https://test.api.rocket.meme/qr"

// export const UPLOAD_API:string = "https://upload.rocket.meme"
// export const API:string = "https://api.rocket.meme"
// export const QR:string = "https://api.rocket.meme/qr"

export const API:string = process.env.REACT_APP_API || "https://api.rocket.meme"
export const QR:string = process.env.REACT_APP_QR || "https://api.rocket.meme/qr"
export const UPLOAD_API:string = process.env.REACT_APP_UPLOAD_API || "https://upload.rocket.meme"
