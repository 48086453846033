import React from 'react';

import './CircleTimer.css';
import {  Badge } from 'react-bootstrap';

interface BadgeTimerProps {
    seconds: number;
    blocks: number;
    canStart: boolean;
    started: boolean;
    bg: string;
}

class BadgeTimer extends React.Component<BadgeTimerProps> {

    toHHMM(seconds: number): string {
        if (seconds < 0) {
            return '00:00';
        }
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - hours * 3600) / 60);
        return `${hours < 10 ? "0" + hours : hours}h ${minutes < 10 ? "0" + minutes : minutes}m`
    }

    render() {
        const { seconds, blocks, canStart, started } = this.props;
        return (
            <Badge className='z4' bg={canStart && !started ? 'secondary' : this.props.bg}
            >
                {blocks > 0 ? 
                    <span style={{
                        fontWeight: 'normal',
                    }}>Countdown: {this.toHHMM(seconds)}</span>
                 : null
                }

                {canStart && !started ? 
                    <span style={{
                        fontWeight: 'normal',
                    }}>Ready to launch</span>
                 : null}

                {started ?
                    <span style={{
                        fontWeight: 'normal',
                    }}>FairMint ended</span>
                 : null}
            </Badge>
        );
    }
}

export default BadgeTimer;