import { Provider, TransactionResponse } from "ethers";
import React from "react";
import { Chain } from "../../config/chains";
import { ethers } from "ethers";
import { IFairLaunchFactory } from "../../abi/IFairLaunchFactory.abi";
import { IUniLocker } from "../../abi/IUniLocker.abi";
import { INonfungiblePositionManager } from "../../abi/INonfungiblePositionManager.abi";
import { Button } from "react-bootstrap";
import { connect } from "../../utils/dapp";
import "./LPLockCard.css";


export interface LockerNFT {
    token0: string;
    token1: string;

    token0symbol: string;
    token1symbol: string;

    token0Amount: bigint;
    token1Amount: bigint;

    tokensOwed0: bigint;
    tokensOwed1: bigint;

    liquidity: bigint;
    fee: bigint;

    tokenId: bigint | number;
    lpTokenId: bigint;

    meta: LPMeta;
    symbol: string;
}

export interface LPMeta {
    name: string;
    description: string;
    image: string;
}

interface BenefitsWidgetProps {
    provider?: Provider;
    chain: Chain;
    tokenId: bigint | number;
    onClaimSuccess?: (tx: TransactionResponse) => void;
}

interface BenefitsWidgetState {
    nft?: LockerNFT;
    lockerAddress?: string;
    lpTokenAddress?: string;
    lpTokenId?: bigint;
}

class LPLockCard extends React.Component<BenefitsWidgetProps, BenefitsWidgetState> {

    constructor(props: BenefitsWidgetProps) {
        super(props);
        this.state = {
            nft: undefined,
            lockerAddress: undefined,
            lpTokenAddress: undefined
        };
    }

    componentDidMount() {
        this.init();
    }

    init = async () => {
        const fairLaunchFactoryContract = new ethers.Contract(
            this.props.chain.fairLaunchFactory,
            IFairLaunchFactory,
            this.props.provider
        );

        const locker = await fairLaunchFactoryContract.locker();

        this.setState({
            lockerAddress: locker
        });

        const lockerContract = new ethers.Contract(
            locker,
            IUniLocker,
            this.props.provider
        );

        const lockerNFT = await lockerContract.lockItem(this.props.tokenId);

        const lpTokenAddress = lockerNFT.lpToken;

        const lpTokenId = lockerNFT.amountOrId;

        const nonfungiblePositionManager = new ethers.Contract(
            lpTokenAddress,
            INonfungiblePositionManager,
            this.props.provider,
        );
        const tokenURI = await nonfungiblePositionManager.tokenURI(lpTokenId);
        const position = await nonfungiblePositionManager.positions(lpTokenId);
        const nftSymbol = await nonfungiblePositionManager.symbol();

        console.log(position);

        const fee = position[0]

        // const operator = position[1]

        const token0 = position[2]
        const token1 = position[3]

        // const ticketLower = position[5]
        // const ticketUpper = position[6]

        const liquidity = position[7]

        // const feeGrowthInside0LastX128 = position[8]
        // const feeGrowthInside1LastX128 = position[9]
        const tokenOwed0 = position[10]
        const tokenOwed1 = position[11]

        const meta: LPMeta = tokenURI.startsWith("data:application/json;base64") ?
            JSON.parse(atob(tokenURI.split(",")[1]))
            : {
                name: "LP Token",
                description: "LP Token",
                image: tokenURI
            };

        const token0symbol = await this.getERC20Meta(token0)
        const token1symbol = await this.getERC20Meta(token1)


        const nft: LockerNFT = {
            token0: token0,
            token1: token1,

            token0symbol: token0symbol.symbol,
            token1symbol: token1symbol.symbol,

            token0Amount: BigInt(0),
            token1Amount: BigInt(0),
            tokensOwed0: tokenOwed0,
            tokensOwed1: tokenOwed1,
            liquidity: liquidity,
            fee: fee,
            tokenId: this.props.tokenId,
            lpTokenId: lpTokenId,
            meta: meta,
            symbol: nftSymbol
        }

        this.setState({
            nft: nft,
            lockerAddress: locker,
            lpTokenAddress: lpTokenAddress,
            lpTokenId: lpTokenId
        });
    }

    add2Wallet = async () => {
        // import nft[lockerAddress, lpTokenId] to wallet
        if (!this.state.lockerAddress) {
            return;
        }
        const options = await connect()
        if (!options) {
            return;
        }
    }

    getERC20Meta = async (address: string) => {
        if (!this.props.provider) {
            return {
                symbol: "Unknown"
            };
        }
        const contract = new ethers.Contract(
            address,
            [
                "function name() view returns (string)",
                "function symbol() view returns (string)",
                "function decimals() view returns (uint8)"
            ],
            this.props.provider
        );

        const symbol = await contract.symbol();

        return {
            symbol: symbol,
        };
    }

    doClaim = async () => {
        if (!this.state.lockerAddress) {
            return;
        }
        const options = await connect()
        if (!options) {
            return;
        }
        const provider = options.provider;
        const signer = await provider.getSigner();
        const lockerContract = new ethers.Contract(
            this.state.lockerAddress,
            IUniLocker,
            signer
        );

        const tx = await lockerContract.claimProfit(this.props.tokenId);
        if (this.props.onClaimSuccess) {
            this.props.onClaimSuccess(tx);
        }
    }

    render() {
        const { nft } = this.state;
        return (
            <div className="benefits-widget">
                {nft ?
                    <div className="benefits-widget-content">
                        <img className="benefits-widget-cover" src={nft.meta.image} alt={nft.meta.name} />
                        <div>
                            <div className="z3">
                                <a href={
                                    this.props.chain.blockExplorer + "/nft/" + this.state.lockerAddress + "/" + nft.tokenId
                                } target="_blank" rel="noreferrer">Uni Locker {'#' + nft.tokenId}</a>
                            </div>
                            <div className="z3">
                                <a href={this.props.chain.blockExplorer + "/token/" + nft.token0} target="_blank" rel="noreferrer">{nft.token0symbol}</a>
                                &nbsp;-&nbsp;
                                <a href={this
                                    .props.chain.blockExplorer + "/token/" + nft.token1} target="_blank" rel="noreferrer">{nft.token1symbol}</a>
                            </div>
                            <div className="z3"><a target="_blank" href={
                                this.props.chain.blockExplorer + "/nft/" + this.state.lpTokenAddress + "/" + nft.lpTokenId
                            } rel="noreferrer">{nft.symbol} {'#' + nft.lpTokenId}</a> / {this.props.chain.feePool / 10000} % fee tier</div>
                            {/* <div className="z4">
                                // {nft.meta.description}
                            </div> */}
                            {/* <div>
                                Locked Liquidity
                            </div>
                            <div>
                                {nft.token0symbol}: <span style={{color: '#fe6ffe'}}>{ethers.formatEther(nft.token0Amount)}</span>
                            </div>
                            <div>
                                {nft.token1symbol}: <span style={{color: '#fe6ffe'}}>{ethers.formatEther(nft.token1Amount)}</span>
                            </div> */}

                            {/* <div className="benefits-widget-title z4 bold">
                                Unclaimed Fees
                            </div>
                            <div className="z4">
                                {nft.token0symbol}: <span style={{color: '#fe6ffe'}}>{ethers.formatEther(nft.tokensOwed0)}</span>
                            </div>
                            <div className="z4">
                                {nft.token1symbol}: <span style={{color: '#fe6ffe'}}>{ethers.formatEther(nft.tokensOwed1)}</span>
                            </div> */}

                            <div className="benefits-widget-title z4 bold">
                                Claim Fees
                            </div>

                            <div style={{
                                display: 'flex',
                                marginTop: '1rem',
                                justifyContent: 'space-between',
                                gap: '1rem'
                            }}>
                                <Button variant="warning" onClick={() => {
                                    this.doClaim();
                                }}>Claim</Button>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        );
    }
}

export default LPLockCard;