import React from 'react';

import "./FactoryPage.css";
import Header from '../components/header/Header';
import { Chain, CHAINS, CurrentChain } from '../config/chains';
import Footer from '../components/footer/Footer';
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { connect, isInWeb3Browser, onAccountChange, onChainSwitch, toEIP55, tryConnectSilent } from '../utils/dapp';
import { Contract, ethers, Provider } from 'ethers';

import PlusIcon from '../assets/icon/plus.png';
import InformationIcon from '../assets/icon/information.png';

import { UPLOAD_API } from '../config/api';
import { V4_FACTORY_ABI } from '../abi/FairLaunchLimitAmountFactory.abi';
import copy from 'copy-to-clipboard';

interface FactoryPageState {
    chain?: Chain;
    address?: string;
    step: number;
    steps: Array<Step>;
    provider?: Provider;
    blockNumber?: number;

    showAlert: boolean;
    alertMessage: string;

    txHash?: string;
    txWaiting: boolean;
    showTxResult: boolean;
    txSuccess: boolean;

    price: bigint | number;

    params: FactoryParams;

    contractAddress?: string;

    loading: boolean;
}

interface MetaItem {
    trait_type: string;
    value: string;
}

interface FactoryParams {
    salt: string;
    name: string;
    symbol: string;
    block: number;
    projectOwner: string;
    totalSupply: bigint | number;
    meta: Array<MetaItem>;

    uniswapV3Factory: string;
    uniswapV3PositionManager: string;

    image: string;
    description: string;

    x?: string;
    discord?: string;
    youtube?: string;
    telegram?: string;
    gitbook?: string;
    website?: string;

    contractAddress: string;

    price: number | string;
    shares: number;
    maxPerAddress: number;

}

interface FactoryPageProps {

}

interface Step {
    title: string;
    description: string;
    icon?: any;
}

class FactoryPage extends React.Component<FactoryPageProps, FactoryPageState> {
    constructor(props: FactoryPageProps) {
        super(props);
        this.state = {
            chain: undefined,
            address: undefined,
            loading: false,
            steps: [{
                title: 'Step 1 - Token basics',
                description: 'Basic information of token'
            }, {
                title: 'Step 2 - Social media',
                description: 'Social media'
            },
            {
                title: 'Step 3 - Launch settings',
                description: 'Launch settings (price and mint units)'
            },
            {
                title: 'Finish - Preview and issue',
                description: 'Preview and issue'
            }],
            step: 0,
            blockNumber: 0,
            provider: undefined,

            showAlert: false,
            alertMessage: '',

            txWaiting: false,
            showTxResult: false,
            txSuccess: false,

            price: 0,

            contractAddress: undefined,

            params: {
                salt: '0',
                name: '',
                symbol: '',
                block: 0,
                projectOwner: '',
                totalSupply: 20000000000,
                meta: [],

                uniswapV3Factory: '',
                uniswapV3PositionManager: '',

                image: '',
                // image: 'https://assets.rocket.meme/assets/2024080818/07a17488-a93a-4bb9-9930-6f0c2d10daa2.png',
                description: '',

                x: undefined,
                discord: undefined,
                youtube: undefined,
                telegram: undefined,
                gitbook: undefined,
                website: undefined,

                contractAddress: '',

                price: 0.01,
                shares: 1000,
                maxPerAddress: 1,
            }
        };
    }

    componentDidMount(): void {
        this.initSilent();
    }

    alert = (message: string) => {
        this.setState({
            showAlert: true,
            alertMessage: message
        });
    }

    initSilent = async () => {
        if (!isInWeb3Browser()) {
            return;
        }
        // supported chain names
        console.log("supported: " + Object.keys(CHAINS).map((key) => CHAINS[Number(key)].name).join(', '))
        // const result = await conn();
        const result = await tryConnectSilent();
        if (result === null) {
            return;
        }

        const { chainId, provider } = result;
        const numChainId = Number(chainId);

        if (CHAINS[numChainId] === undefined) {
            return;
        }

        const blockNumber = await provider.getBlockNumber();

        this.setState({
            chain: CHAINS[numChainId],
            provider: provider,
            blockNumber: blockNumber,
        });

        if (result.address !== undefined) {
            const { params } = this.state;
            params.projectOwner = toEIP55(result.address);

            this.setState({
                address: toEIP55(result.address),
                params,
            });
        }
        this.watchChange();
    }

    init = async () => {
        if (!isInWeb3Browser()) {
            this.alert('Please install MetaMask.');
            return;
        }

        try {
            const result = await connect()

            if (result === null) {
                return;
            }

            const { chainId, address } = result;
            const numChainId = Number(chainId);

            if (CHAINS[numChainId] === undefined) {
                // not support
                this.alert('Chain not supported, only ' + (
                    Object.keys(CHAINS).map((key) => CHAINS[Number(key)].name).join(', ')
                ) + ' are supported.');
                return;
            }

            console.log('chainId:', chainId)
            const { params } = this.state;
            params.projectOwner = toEIP55(address);

            this.setState({
                address: toEIP55(address),
                chain: CHAINS[numChainId],
                provider: result.provider,
                params,
            });
            this.watchChange();
        } catch (error) {
            console.error(error)
        }
    }

    watchChange = async (): Promise<void> => {
        onAccountChange((accounts: any) => {
            this.setState({
                address: toEIP55(accounts[0])
            })
        })

        onChainSwitch((chainId: number | string) => {
            console.log('chainId:', chainId)
            this.setState({
                chain: CHAINS[Number(chainId)],
            });
            this.initSilent();
            if (CHAINS[Number(chainId)] === undefined) {
                // not support
                alert('Chain not supported:' + chainId)
                return;
            }
        })
    }


    render() {
        const { chain, address, step, params } = this.state;
        const symbol = process.env.REACT_APP_BASE_CURRENCY
        return (
            <div className="FactoryPage">
                <Header address={address ?? ''} chain={chain} showMessage={false} onConnectClick={() => { this.init() }} />
                <Container>
                    <Row style={{
                        marginTop: "1.2rem",
                    }}>
                        <Col sm={12} lg={8} md={8}>
                            {step === 0 ? <div>
                                {/* basics */}
                                <Card  >
                                    <Card.Header>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                            <div className="z2" style={{
                                                color: '#fe6ffe'
                                            }}>
                                                Start a new coin
                                            </div>
                                            <div>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}>
                                                    <div>
                                                        <Button variant="warning" type="submit" onClick={(e) => {
                                                            // this._checkBaseInfo();
                                                            if (!this.checkBasiInfo(params)) {
                                                                return;
                                                            }
                                                            this.setState({
                                                                step: 1
                                                            });
                                                        }}>
                                                            Next
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Alert variant="dark" style={{
                                            border: 'none'
                                        }} className='z3'>
                                            You need to prepare:<br />
                                            1. Coin details and logo, as well as official website or social media.<br />
                                            2. Some ${symbol} as GAS fee<br />
                                            Create your coin now!
                                        </Alert>
                                        {/* chain name display*/}
                                        <Form.Group className="mb-3" controlId="formChain" as={Row} >
                                            <Form.Label column sm={2}>Chain</Form.Label>
                                            <Col sm={10} style={{
                                                alignContent: "center",
                                            }}>
                                                <div>
                                                    {this.state.chain ? this.state.chain.name : 'Not Connected'}
                                                </div>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formName" as={Row} >
                                            <Form.Label column sm={2}>* Name</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control type="text" placeholder="Ethereum"
                                                    onChange={(e) => {
                                                        params.name = e.target.value;
                                                        this.setState({
                                                            params
                                                        })
                                                    }
                                                    }
                                                    // this.state.name.length > 16 and alpha beta and space
                                                    isInvalid={params.name !== '' && (/[^a-zA-Z0-9\s]/.test(params.name) || params.name.length > 26)}
                                                    isValid={params.name !== '' && /^[a-zA-Z0-9\s]+$/.test(params.name) && params.name.length <= 26}
                                                    maxLength={26}
                                                    value={params.name}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formSymbol" as={Row} >
                                            <Form.Label column sm={2}>* Symbol</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control type="text" placeholder={symbol}
                                                    onChange={(e) => {
                                                        params.symbol = e.target.value;
                                                        this.setState({
                                                            params
                                                        })
                                                    }}
                                                    // this.state.symbol.length > 6 and alpha beta and space
                                                    isInvalid={params.symbol !== '' && (/[^a-zA-Z0-9\s]/.test(params.symbol) || params.symbol.length > 10)}
                                                    isValid={params.symbol !== '' && /^[a-zA-Z0-9]+$/.test(params.symbol) && params.symbol.length <= 10}
                                                    maxLength={10}
                                                    value={params.symbol}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formLogo" as={Row} >
                                            <Form.Label column sm={2}>* Logo</Form.Label>
                                            <Col sm={10}>
                                                <div style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    border: "1px solid #495057",
                                                    borderRadius: "5px",
                                                }} onClick={(e) => {
                                                    document.getElementById('fileInput')?.click();
                                                }}>
                                                    {!params.image ? <img src={PlusIcon} alt="logo" style={{
                                                        width: "30px",
                                                        height: "30px",
                                                    }} /> : <img src={params.image} alt="logo" style={{
                                                        width: "100px",
                                                        height: "100px",
                                                    }} />}
                                                </div>
                                                <Form.Text id="formLogoHelpBlock" muted>
                                                    Recommended size: 300x300 px, supports jpg, png, gif formats, Max size: 1MB
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={(e) => {
                                            this.doUpload(e);
                                        }}></input>

                                        {/* <Form.Group className="mb-3" controlId="formTotalSupply" as={Row} >
                                            <Form.Label column sm={2}>* Total Supply</Form.Label>
                                            <Col sm={10}>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        disabled={true}
                                                        placeholder="100000000"
                                                        type="number"
                                                        aria-label="Total Supply"
                                                        aria-describedby="basic-addon2"
                                                        onChange={(e) => {
                                                            // this.setState({ totalSupply: e.target.value });
                                                            if (e.target.value === '') {
                                                                params.totalSupply = Number(0);
                                                            } else {
                                                                params.totalSupply = Number(e.target.value);
                                                            }
                                                            this.setState({
                                                                params
                                                            })
                                                        }
                                                        }
                                                        maxLength={18}
                                                        minLength={1}
                                                        isValid={params.totalSupply > 0 && (params.totalSupply + '').length < 18}
                                                        isInvalid={params.totalSupply <= 0 || (params.totalSupply + '').length >= 18}
                                                        value={params.totalSupply + ''}

                                                    />
                                                    <InputGroup.Text id="basic-addon2"> * 10 ** 18</InputGroup.Text>
                                                </InputGroup>
                                                 <Form.Text id="formLogoHelpBlock" muted>
                                                    {
                                                        (params.totalSupply + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                    }
                                                    {
                                                        ' ' + params.symbol
                                                    }
                                                    (50% FairMint, 50% added to LP)
                                                </Form.Text> 
                                            </Col>
                                        </Form.Group> */}

                                        {/* textarea of description */}
                                        <Form.Group className="mb-3" controlId="formDescription" as={Row} >
                                            <Form.Label column sm={2}>* Description</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control as="textarea" placeholder="No more than 200 characters, this description will be displayed on the blockchain. " rows={3}
                                                    maxLength={1024}
                                                    isInvalid={params.description !== '' && params.description.length > 200}
                                                    onChange={
                                                        (e) => {
                                                            // this.setState({ description: e.target.value });
                                                            params.description = e.target.value;
                                                            this.setState({
                                                                params
                                                            })
                                                        }
                                                    }
                                                    value={params.description}
                                                />
                                                <Form.Text>{params.description.length + ''} / 200</Form.Text>
                                            </Col>
                                        </Form.Group>

                                    </Card.Body></Card>
                                {/* end of basics */}
                            </div> : null}
                            {step === 1 ? <div>
                                {/* social media */}
                                <Card >
                                    <Card.Header>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                            <Button variant="secondary" type="submit" onClick={(e) => {
                                                this.setState({
                                                    step: 0
                                                });
                                            }}>
                                                Previous
                                            </Button>
                                            <Button variant="warning" type="submit" onClick={(e) => {
                                                // this._setTab(2);
                                                if (this.checkMediaInfo()) {
                                                    this.setState({
                                                        step: 2
                                                    });
                                                }

                                            }}>
                                                Next
                                            </Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        {/* Website */}
                                        <Form.Group className="mb-3" controlId="formWebsite" as={Row} >
                                            <Form.Label column sm={2} style={{
                                                alignItems: "center",
                                                display: "flex",
                                            }}>
                                                <img src={require('../assets/icon/website.png')} alt="Website" style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    marginRight: "0.5rem",
                                                }} />
                                                Website
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control type="text" placeholder="(Optional)"
                                                    onChange={(e) => {
                                                        // this.setState({ website: e.target.value });
                                                        if (e.target.value.trim() === '') {
                                                            params.website = undefined;
                                                        }
                                                        params.website = e.target.value;
                                                        this.setState({
                                                            params
                                                        })
                                                    }}
                                                    isValid={params.website !== undefined && /^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(params.website)}
                                                    isInvalid={params.website !== undefined && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(params.website)}
                                                    value={params.website ?? ''}
                                                />
                                            </Col>
                                        </Form.Group>

                                        {/* Telegram */}
                                        <Form.Group className="mb-3" controlId="formTelegram" as={Row} >
                                            <Form.Label column sm={2} style={{
                                                alignItems: "center",
                                                display: "flex",
                                            }}>
                                                <img src={require('../assets/icon/telegram.png')} alt="Telegram" style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    marginRight: "0.5rem",
                                                }} />
                                                Telegram
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control type="text" placeholder="(Optional)"
                                                    onChange={(e) => {
                                                        // this.setState({ telegram: e.target.value });
                                                        if (e.target.value.trim() === '') {
                                                            params.telegram = undefined;
                                                        }
                                                        params.telegram = e.target.value;
                                                        this.setState({
                                                            params
                                                        })
                                                    }}
                                                    isValid={params.telegram !== undefined && /^https?:\/\/t\.me\/[a-zA-Z0-9_]{5,32}$/.test(params.telegram)}
                                                    isInvalid={params.telegram !== undefined && !/^https?:\/\/t\.me\/[a-zA-Z0-9_]{5,32}$/.test(params.telegram)}
                                                    value={params.telegram ?? ''}
                                                />
                                            </Col>
                                        </Form.Group>

                                        {/* gitbook */}
                                        <Form.Group className="mb-3" controlId="formGitbook" as={Row} >
                                            <Form.Label column sm={2}
                                                style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                }}
                                            >
                                                <img src={require('../assets/icon/gitbook.png')} alt="Gitbook" style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    marginRight: "0.5rem",
                                                }} />
                                                Gitbook</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control type="text" placeholder="(Optional)"

                                                    onChange={(e) => {
                                                        // this.setState({ gitbook: e.target.value });
                                                        if (e.target.value.trim() === '') {
                                                            params.gitbook = undefined;
                                                        }
                                                        params.gitbook = e.target.value;
                                                        this.setState({
                                                            params
                                                        })
                                                    }}
                                                    isValid={params.gitbook !== undefined && /^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(params.gitbook)}
                                                    isInvalid={params.gitbook !== undefined && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(params.gitbook)}
                                                    value={params.gitbook ?? ''}
                                                />
                                            </Col>
                                        </Form.Group>

                                        {/* X */}
                                        <Form.Group className="mb-3" controlId="formX" as={Row} >
                                            <Form.Label column sm={2}
                                                style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                }}
                                            >
                                                <img src={require('../assets/icon/x.png')} alt="X" style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    marginRight: "0.5rem",
                                                }} />
                                                X</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control type="text" placeholder="(Optional)"
                                                    //pattern=  ^https://x.com/[a-zA-Z0-9_]{5,32}$
                                                    onChange={(e) => {
                                                        // this.setState({ x: e.target.value });
                                                        if (e.target.value.trim() === '') {
                                                            params.x = undefined;
                                                        }
                                                        params.x = e.target.value;
                                                        this.setState({
                                                            params
                                                        })
                                                    }}
                                                    // prefix of https://x.com/xxxxx or prefix of https://twitter.com
                                                    isValid={params.x !== undefined && (/^https?:\/\/x\.com\/[a-zA-Z0-9_\\-]+?$/.test(params.x) || /^https?:\/\/twitter\.com/.test(params.x))}
                                                    isInvalid={params.x !== undefined && !/^https?:\/\/x\.com\/[a-zA-Z0-9_\\-]+?$/.test(params.x) && !/^https?:\/\/twitter\.com/.test(params.x)}
                                                    value={params.x ?? ''}
                                                />
                                            </Col>
                                        </Form.Group>

                                        {/* Discord */}
                                        <Form.Group className="mb-3" controlId="formDiscord" as={Row} >
                                            <Form.Label column sm={2} style={{
                                                alignItems: "center",
                                                display: "flex",
                                            }}>
                                                <img src={require('../assets/icon/discord.png')} alt="Discord" style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    marginRight: "0.5rem",
                                                }} />

                                                Discord</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control type="text" placeholder="(Optional)"

                                                    onChange={(e) => {
                                                        // this.setState({ discord: e.target.value });
                                                        if (e.target.value.trim() === '') {
                                                            params.discord = undefined;
                                                        }
                                                        params.discord = e.target.value;
                                                        this.setState({
                                                            params
                                                        })
                                                    }}

                                                    isValid={params.discord !== undefined && /^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(params.discord)}
                                                    isInvalid={params.discord !== undefined && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(params.discord)}
                                                    value={params.discord ?? ''}
                                                />
                                            </Col>
                                        </Form.Group>

                                        {/* Youtube */}
                                        <Form.Group className="mb-3" controlId="formYoutube" as={Row} >
                                            <Form.Label column sm={2} style={{
                                                alignItems: "center",
                                                display: "flex",
                                            }}>
                                                <img src={require('../assets/icon/youtube.png')} alt="Youtube" style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    marginRight: "0.5rem",
                                                }} />Youtube
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control type="text" placeholder="(Optional)"
                                                    onChange={(e) => {
                                                        if (e.target.value.trim() === '') {
                                                            params.youtube = undefined;
                                                        }
                                                        params.youtube = e.target.value;
                                                        this.setState({
                                                            params
                                                        })
                                                    }}
                                                    isValid={params.youtube !== undefined && /^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(params.youtube)}
                                                    isInvalid={params.youtube !== undefined && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(params.youtube)}
                                                    value={params.youtube ?? ''}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                {/* end of social media */}
                            </div> : null}
                            {step === 2 ? <div>
                                {/* launch settings */}
                                <Card >
                                    <Card.Header>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                            <Button variant="secondary" type="submit" onClick={(e) => {
                                                this.setState({
                                                    step: 1
                                                });
                                            }}>
                                                Previous
                                            </Button>
                                            <Button variant="warning" type="submit" onClick={(e) => {
                                                if (this.checkTokenomicsInfo()) {
                                                    this.prepareSubmit();
                                                    this.setState({
                                                        step: 3
                                                    });
                                                }

                                            }}>
                                                Next
                                            </Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        {/* Number of shares*/}
                                        <Form.Group className="mb-3" controlId="formMaxLPLimit" as={Row} >
                                            <Form.Label column sm={2}>* Number of shares</Form.Label>
                                            <Col sm={10}>
                                                <InputGroup>
                                                    <Form.Control type="number" placeholder="1000"
                                                        onChange={(e) => {
                                                            if (e.target.value === '') {
                                                                params.shares = 0;
                                                            }
                                                            params.shares = parseInt(e.target.value);
                                                            this.setState({
                                                                params
                                                            })
                                                        }
                                                        }
                                                        maxLength={10}
                                                        isValid={Number(params.shares) >= 1 && Number(params.shares) <= 10000000}
                                                        isInvalid={(Number(params.shares) < 1 || Number(params.shares) > 10000000)}
                                                        value={params.shares + ''}
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                        Shares
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        {/* price */}
                                        <Form.Group className="mb-3" controlId="formPrice" as={Row} >
                                            <Form.Label column sm={2}>* Price per share</Form.Label>
                                            <Col sm={10}>
                                                <InputGroup>
                                                    <Form.Control type="number" placeholder="0.01"
                                                        onChange={(e) => {
                                                            if (e.target.value === '') {
                                                                params.price = 0;
                                                            }
                                                            params.price = e.target.value;
                                                            this.setState({
                                                                params
                                                            })
                                                        }}
                                                        maxLength={10}
                                                        isValid={Number(params.price) > 0 && Number(params.price) <= 1000}
                                                        isInvalid={(Number(params.price) <= 0 || Number(params.price) > 1000)}
                                                        value={params.price + ''}
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                        {this.state.chain ? this.state.chain.symbol : '-'}
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        {/* limit  */}
                                        <Form.Group className="mb-3" controlId="formLimit" as={Row} >
                                            <Form.Label column sm={2}>* Maximum mint per wallet</Form.Label>
                                            <Col sm={10}>
                                                <InputGroup>
                                                    <Form.Control type="number" placeholder="1000"
                                                        onChange={(e) => {
                                                            if (e.target.value === '') {
                                                                params.maxPerAddress = 0;
                                                            }
                                                            params.maxPerAddress = parseInt(e.target.value);
                                                            this.setState({
                                                                params
                                                            })
                                                        }
                                                        }
                                                        maxLength={10}
                                                        isValid={Number(params.maxPerAddress) >= 1 && Number(params.maxPerAddress) <= 10000000}
                                                        isInvalid={(Number(params.maxPerAddress) < 1 || Number(params.maxPerAddress) > 10000000)}
                                                        value={params.maxPerAddress + ''}
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                        {/* {this.state.chain ? this.state.chain.symbol : '-'} */}
                                                        Shares
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                {/* end of launch settings */}
                            </div> : null}
                            {step === 3 ? <div>
                                {/* preview and issue */}
                                <Card >
                                    <Card.Header>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                            <Button variant="secondary" type="submit" onClick={(e) => {
                                                this.setState({
                                                    step: 2
                                                });
                                            }}>
                                                Previous
                                            </Button>
                                            <Button variant="warning" type="submit"
                                                onClick={(e) => {
                                                    // TODO this._checkSubmit()
                                                    this.doSubmit();
                                                }}
                                            >
                                                Issue {
                                                    this.state.price > 0 ? ' (Fee: ' + ethers.formatEther(this.state.price) + ' ' + symbol + ')' : ''
                                                }
                                            </Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Alert variant="secondary">
                                            <div style={{
                                                display: "flex",

                                            }}>
                                                <div>
                                                    <img src={params.image} alt="logo" style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        marginRight: "1rem"
                                                    }} />
                                                </div>
                                                <div style={{
                                                    width: "100%",
                                                }}>
                                                    <div className='infoLine'>
                                                        <div>Name </div>
                                                        <div>{params.name}</div>
                                                    </div>
                                                    <div className='infoLine'>
                                                        <div>Symbol</div>
                                                        <div>{params.symbol}</div>
                                                    </div>
                                                    <div className='infoLine'>
                                                        <div>Total Supply</div>
                                                        <div>{params.totalSupply + ''}</div>
                                                    </div>
                                                    <div className='infoLine'>
                                                        <div>Chain </div>
                                                        <div>{this.state.chain ? this.state.chain.name : ''}</div>
                                                    </div>
                                                    {/* description */}
                                                    <div className='infoLine'>
                                                        <div>Description </div>
                                                        <div>{params.description}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Alert>

                                        <Alert variant="secondary">
                                            <div className='infoLine'>
                                                <div>Creator: </div>
                                                <div style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                }} onClick={(e) => {
                                                    if (this.state.chain && params.projectOwner) {
                                                        const url = this.state.chain.blockExplorer + '/address/' + params.projectOwner;
                                                        window.open(url, '_blank');
                                                    }
                                                }}>
                                                    <div>{params.projectOwner.length > 40 ?
                                                        params.projectOwner.substring(0, 8) + '...' + params.projectOwner.substring(params.projectOwner.length - 6)
                                                        : ''}
                                                    </div>
                                                    <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                        width: "0.8rem",
                                                        height: "0.8rem",
                                                        marginLeft: "0.5rem"
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='infoLine'>
                                                <div>Number of shares: </div>
                                                <div>{params.shares + ''}
                                                </div>
                                            </div>
                                            <div className='infoLine'>
                                                <div>Price per share: </div>
                                                <div>{params.price + ''}
                                                    {chain ? ' ' + chain.symbol : ''}
                                                </div>
                                            </div>
                                            <div className='infoLine'>
                                                <div>Maximum mint per wallet: </div>
                                                <div>{params.maxPerAddress + ''} Shares
                                                </div>
                                            </div>

                                        </Alert>

                                        <Alert variant="secondary">
                                            {/* Media info */}
                                            <div className='infoLine'>
                                                <div>Website: </div>
                                                <div style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                }} onClick={(e) => {
                                                    if (params.website) {
                                                        window.open(params.website, '_blank');
                                                    }
                                                }}>
                                                    <div>{params.website}</div>
                                                    <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                        width: "0.8rem",
                                                        height: "0.8rem",
                                                        marginLeft: "0.5rem"
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='infoLine'>
                                                <div>Telegram: </div>
                                                <div style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                }} onClick={(e) => {
                                                    if (params.telegram) {
                                                        window.open(params.telegram, '_blank');
                                                    }
                                                }}>
                                                    <div>{params.telegram}</div>
                                                    <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                        width: "0.8rem",
                                                        height: "0.8rem",
                                                        marginLeft: "0.5rem"
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='infoLine'>
                                                <div>Gitbook: </div>
                                                <div style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                }} onClick={(e) => {
                                                    if (params.gitbook) {
                                                        window.open(params.gitbook, '_blank');
                                                    }
                                                }}>
                                                    <div>{params.gitbook}</div>
                                                    <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                        width: "0.8rem",
                                                        height: "0.8rem",
                                                        marginLeft: "0.5rem"
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='infoLine'>
                                                <div>X: </div>
                                                <div style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                }} onClick={(e) => {
                                                    if (params.x) {
                                                        window.open(params.x, '_blank');
                                                    }
                                                }}>
                                                    <div>{params.x}</div>
                                                    <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                        width: "0.8rem",
                                                        height: "0.8rem",
                                                        marginLeft: "0.5rem"
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='infoLine'>
                                                <div>Discord: </div>
                                                <div style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                }} onClick={(e) => {
                                                    if (params.discord) {
                                                        window.open(params.discord, '_blank');
                                                    }
                                                }}>
                                                    <div>{params.discord}</div>
                                                    <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                        width: "0.8rem",
                                                        height: "0.8rem",
                                                        marginLeft: "0.5rem"
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='infoLine'>
                                                <div>Youtube: </div>
                                                <div style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                }} onClick={(e) => {
                                                    if (params.youtube) {
                                                        window.open(params.youtube, '_blank');
                                                    }
                                                }}>
                                                    <div>{params.youtube}</div>
                                                    <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                        width: "0.8rem",
                                                        height: "0.8rem",
                                                        marginLeft: "0.5rem"
                                                    }} />
                                                </div>
                                            </div>

                                        </Alert>
                                    </Card.Body>
                                </Card>
                                {/* end of preview and issue */}
                            </div> : null}
                        </Col>
                        <Col sm={12} lg={4} md={4}>
                            {/* steps */}
                            {
                                this.state.steps.map((s, index) => {
                                    return <div key={index} className={"stepItems" + (step === index ? ' active' : '')}>
                                        <div>
                                            {s.title}
                                        </div>
                                        {/* <div>
                                            {s.description}
                                        </div> */}
                                    </div>
                                })
                            }
                        </Col>
                    </Row>
                </Container>
                <Footer />

                {/* show alert */}
                <Modal centered show={this.state.showAlert}>
                    <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
                        <div><img src={InformationIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /></div>
                        <div>{this.state.alertMessage}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showAlert: false
                            });
                        }}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* show tx result */}
                {/* mask */}
                <Modal show={this.state.showTxResult} onHide={() => {
                    this.setState({
                        showTxResult: false
                    });
                }}>
                    {/* <Modal.Header closeButton>
                    <Modal.Title>Transaction Result</Modal.Title>
                </Modal.Header> */}
                    <Modal.Body>
                        <div style={{
                            textAlign: "center",
                            marginBottom: "2rem",
                            marginTop: "2rem",
                        }}>
                            {/* waiting */}
                            {this.state.txWaiting ? <img src={require('../assets/icon/waiting.png')} alt="waiting" style={{
                                width: "100px",
                                height: "100px",
                                margin: "0 auto",
                                display: "block",
                            }} /> : this.state.txSuccess ? <img src={require('../assets/icon/success.png')} alt="success" style={{
                                width: "100px",
                                height: "100px",
                                margin: "0 auto",
                                display: "block",
                            }} /> : <img src={require('../assets/icon/error.png')} alt="error" style={{
                                width: "100px",
                                height: "100px",
                                margin: "0 auto",
                                display: "block",
                            }} />}
                            <div style={{
                                marginTop: '2rem',
                                display: 'flex',
                                gap: '1rem',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img src={params.image} alt="logo" style={{
                                    width: "1rem",
                                    height: "1rem",
                                }} />
                                <Alert.Link href={
                                    this.state.chain ? this.state.chain.blockExplorer + '/tx/' + this.state.txHash : "#"
                                } target="_blank">

                                    {/* token name and symbol */}
                                    {params.name} ({params.symbol})
                                </Alert.Link>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "1rem",
                        }}>
                            <div style={{
                                display: "flex",
                                gap: "0.5rem",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <b>CA:</b>

                                <Alert.Link style={{
                                    wordBreak: 'break-all',
                                    fontWeight: 'normal',
                                    width: '60%',
                                }} href={
                                    this.state.chain ? this.state.chain.blockExplorer + '/address/' + this.state.contractAddress : '#'
                                } target="_blank">{this.state.contractAddress}</Alert.Link>

                                {/* copy image */}
                                <img src={require('../assets/icon/copy.png')} alt="copy" style={{
                                    width: "1rem",
                                    height: "1rem",
                                    marginLeft: "0.5rem",
                                    cursor: "pointer",
                                }} onClick={(e) => {
                                    copy(this.state.contractAddress ?? '');
                                    // toast success
                                    alert('Copied to clipboard');
                                }} />
                            </div>

                        </div>
                        {/* <div style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "1rem",
                            gap: "1rem",
                        }}>
                            <div><b>FairMint deadline block: </b></div>
                            <div>{
                                '#' + this.state.newblocks
                            }</div>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "1rem",
                            gap: "1rem",
                        }}>
                            <div><b>URL:</b> </div>
                            <Alert.Link
                                href={`https://rocket.meme/dt?address=${this.state.contractAddress}`} target="_blank"
                                style={{
                                    wordBreak: 'break-all',
                                    fontWeight:'normal'
                                }}>https://rocket.meme/dt?address={this.state.contractAddress}</Alert.Link>
                            <img src={require('../assets/icon/copy.png')} alt="copy" style={{
                                width: "1rem",
                                height: "1rem",
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                            }} onClick={(e) => {
                                copy(`https://rocket.meme/dt?address=${this.state.contractAddress}`);
                                // toast success
                                alert('Copied to clipboard');
                            }} />
                        </div> */}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={(e) => {
                            this.setState({
                                showTxResult: false
                            });
                            // reload
                            window.location.href = '/mine?tab=1'
                        }}>
                            I created
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* loading cover */}
                <Modal show={this.state.loading} centered>
                    <Modal.Body style={{ textAlign: 'center' }}>
                        <div>Loading...</div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

    checkBasiInfo = (params: FactoryParams): boolean => {
        if (!this.state.address) {
            this.alert('Please connect wallet first.');
            return false;
        }
        // is chain supported? 
        if (!this.state.chain) {
            this.alert('Chain not supported.');
            return false;
        }
        // is name valid
        if (params.name === '') {
            this.alert('Name is required.');
            return false;
        }
        // is name pattern valid
        if (!/^[a-zA-Z0-9\s]+$/.test(params.name) || params.name.length > 26) {
            this.alert('Name is invalid. Only letters, numbers and spaces are allowed, and the length should be less than 16.');
            return false;
        }
        // is symbol valid
        if (params.symbol === '') {
            this.alert('Symbol is required.');
            return false;
        }
        // is symbol pattern valid
        if (!/^[a-zA-Z0-9]+$/.test(params.symbol) || params.symbol.length > 10) {
            this.alert('Symbol is invalid. Only letters and numbers are allowed, and the length should be less than 10.');
            return false;
        }
        // is totalSupply valid
        if (params.totalSupply <= 0 || (params.totalSupply + '').length >= 18) {
            this.alert('Total Supply is invalid. It should be greater than 0 and less than 10^18.');
            return false;
        }
        // is description valid
        if (params.description.length > 200 || params.description === '') {
            this.alert('Description is invalid. It should be less than 200 characters and not empty.');
            return false;
        }
        // image is required
        if (!params.image) {
            this.alert('Logo is required.');
            return false;
        }

        return true;
    }

    checkMediaInfo = (): boolean => {
        // if website set, check pattern
        if (this.state.params.website && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.params.website)) {
            this.alert('Website is invalid.');
            return false;
        }

        // if telegram set, check pattern
        if (this.state.params.telegram && !/^https?:\/\/t\.me\/[a-zA-Z0-9_]{5,32}$/.test(this.state.params.telegram)) {
            this.alert('Telegram is invalid.');
            return false;
        }

        // if gitbook set, check pattern
        if (this.state.params.gitbook && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.params.gitbook)) {
            this.alert('Gitbook is invalid.');
            return false;
        }

        // if x set, check pattern
        if (this.state.params.x && !/^https?:\/\/x\.com\/[a-zA-Z0-9_\\-]+?$/.test(this.state.params.x) && !/^https?:\/\/twitter\.com/.test(this.state.params.x)) {
            this.alert('X is invalid.');
            return false;
        }

        // if discord set, check pattern
        if (this.state.params.discord && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.params.discord)) {
            this.alert('Discord is invalid.');
            return false;
        }

        // if youtube set, check pattern
        if (this.state.params.youtube && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.params.youtube)) {
            this.alert('Youtube is invalid.');
            return false;
        }

        return true;
    }

    checkTokenomicsInfo = (): boolean => {
        // check shares
        if (!this.state.params.shares) {
            this.alert('Number of shares is required.');
            return false;
        }
        if (this.state.params.shares < 1 || this.state.params.shares > 10000000) {
            this.alert('Number of shares can not be less than 1 or greater than 10000000.');
            return false;
        }

        // check price
        if (!this.state.params.price) {
            this.alert('Price is required.');
            return false;
        }
        if (parseFloat(this.state.params.price + '') < 0.0001 || parseFloat(this.state.params.price + '') > 1000) {
            this.alert('Price can not be less than 0.0001 or greater than 1000.');
            return false;
        }
        // check maxPerAddress
        if (!this.state.params.maxPerAddress) {
            this.alert('Max number of shares each EOA is allowed is required.');
            return false;
        }

        if (this.state.params.maxPerAddress < 1 || this.state.params.maxPerAddress > 10000000) {
            this.alert('Max number of shares each EOA is allowed can not be less than 1 or greater than 10000000.');
            return false;
        }

        return true;
    }

    buildMeta = (): string => {
        const meta = []
        const params = this.state.params;
        // description
        if (params.description) {
            meta.push({
                'trait_type': 'description',
                'value': encodeURIComponent(params.description)
            });
        }
        if (params.website) {
            meta.push({
                'trait_type': 'website',
                'value': params.website
            });
        }
        if (params.telegram) {
            meta.push({
                'trait_type': 'telegram',
                'value': params.telegram
            });
        }
        if (params.gitbook) {
            meta.push({
                'trait_type': 'gitbook',
                'value': params.gitbook
            });
        }
        if (params.x) {
            meta.push({
                'trait_type': 'x',
                'value': params.x
            });
        }
        if (params.discord) {
            meta.push({
                'trait_type': 'discord',
                'value': params.discord
            });
        }
        if (params.youtube) {
            meta.push({
                'trait_type': 'youtube',
                'value': params.youtube
            });
        }

        // image
        meta.push({
            'trait_type': 'image',
            'value': params.image
        });

        const metaStr = 'data:application/json;base64,' + btoa(JSON.stringify(meta));
        console.log('metaStr:', metaStr);
        return metaStr;
    }

    doUpload = async (e: any): Promise<void> => {
        // upload image
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        // less 1mb
        if (file.size > 1024 * 1024) {
            alert('The file size is too large.');
            return;
        }
        // check file type
        if (!/image\/(png|jpg|jpeg|gif|svg)/.test(file.type.toLowerCase())) {
            alert('The file type is not supported.');
            return;
        }

        // upload
        const data = await this.uploadFile('/upload', 'file', file);
        console.log(data);
        if (data !== '') {
            const image = 'https://assets.rocket.meme' + data;
            const { params } = this.state;
            params.image = image;

            this.setState({
                params
            });
        }
    }

    uploadFile = async (url: string, fieldName: string, file: any) => {
        const formData = new FormData();
        formData.append(fieldName, file);
        const response = await fetch(UPLOAD_API + url, {
            method: 'POST',
            body: formData
        });
        if (response.status === 429) {
            this.alert('Upload too frequently, please try again later.');
            return '';
        }
        if (response.status !== 200) {
            this.alert('Upload failed, please try again later or change another image.');
            return '';
        }
        const data = await response.text();
        return data;
    }

    toBlockNumbers = (hours: number) => {
        if (this.state.chain) {
            const a = hours * 3600 / this.state.chain.blockSeconds;
            return a.toFixed(0);
        }
        return 0;
    }

    prepareSubmit = async () => {
        if (!this.state.address) {
            this.alert('Please connect wallet first.');
            return;
        }
        if (!this.state.params.projectOwner) {
            this.alert('Please connect wallet first.');
            return;
        }
        if (!this.state.chain) {
            this.alert('Chain not supported.');
            return;
        }

        const metaStr = this.buildMeta();
        // according to the parameters, generate a contract address
        const options = await connect();
        if (!options) {
            this.alert('Please connect wallet first.');
            return;
        }

        const factoryContract = new Contract(this.state.chain?.fairLaunchFactory, V4_FACTORY_ABI,
            options.provider);

        const { params, chain } = this.state;

        /**
         * 
         * struct FairLaunchLimitAmountStruct {
    uint256 price;
    uint256 shares; // 

    uint256 totalSupply;
    address uniswapRouter;
    address uniswapFactory;
    string name;
    string symbol;
    string meta;

    uint256 maxUnitsOfEachAddress;
}
         */

        const p = [
            ethers.parseUnits(params.price + '', 18),
            params.shares,

            ethers.parseUnits(params.totalSupply + '', 18),
            chain.uniswapV3.positionManager,
            chain.uniswapV3.factory,
            params.name,
            params.symbol,
            metaStr,

            params.maxPerAddress
        ]

        // projectOwner
        params.projectOwner = options.address ?? params.projectOwner;

        try {
            // get price
            const price = await factoryContract.price()
            console.log('price:', price);
            this.setState({
                price: price
            });

            const contractAddress = await factoryContract.getTokenAddress(
                params.salt,
                this.state.chain.feePool,
                params.projectOwner,
                p
            );

            console.log('contractAddress:', contractAddress);
            this.setState({
                contractAddress: contractAddress
            });


        } catch (e: any) {
            console.error(JSON.stringify(e));
            const msg = e.data ? (e.data.message || e.data.cause) : (e.reason || e.message);
            this.alert('Failed to call: ' + e.code + ': ' + msg);
        }
    }


    doSubmit = async (): Promise<void> => {
        // this.alert('System maintenance.');
        // return;
        const metaStr = this.buildMeta();
        const options = await connect();

        if (options === null) {
            this.alert('Please connect to MetaMask.');
            return;
        }

        const { chain, params } = this.state;

        if (chain === undefined) {
            this.alert('Please connect to a supported chain.');
            return;
        }

        if (chain.chainId !== CurrentChain.chainId) {
            this.alert('Please connect to the correct chain.');
            return;
        }

        const signer = await options.provider.getSigner();
        const factoryContract = new Contract(chain.fairLaunchFactory, V4_FACTORY_ABI, signer);

        if (params.name.toLowerCase() === 'rocket') {
            this.alert('Name cannot be "Rocket".');
            return;
        }

        if (params.symbol.toLowerCase() === 'rocket') {
            this.alert('Symbol cannot be "Rocket".');
            return;
        }

        // projectOwner
        params.projectOwner = options.address ?? params.projectOwner;

        const p = [
            ethers.parseUnits(params.price + '', 18),
            params.shares,

            ethers.parseUnits(params.totalSupply + '', 18),
            chain.uniswapV3.positionManager,
            chain.uniswapV3.factory,
            params.name,
            params.symbol,
            metaStr,

            params.maxPerAddress
        ]

        try {
            // loading
            this.setState({
                loading: true
            });
            await this.prepareSubmit();

            const tx = await factoryContract.deployToken(
                params.salt,
                chain.feePool,
                p,
                {
                    value: this.state.price
                }
            );
            console.log('tx:', tx);

            this.setState({
                txWaiting: true,
                txHash: tx.hash,
                showTxResult: true,
            });
            await tx.wait(1);

            this.setState({
                txSuccess: true,
                txWaiting: false,
                loading: false,
            });
        } catch (e: any) {
            console.error(e);
            console.error(JSON.stringify(e));
            const msg = e.data ? (e.data.message || e.data.cause) : (e.reason || e.message);
            this.alert('Failed to invoke: ' + e.code + ': ' + msg);
            this.setState({
                txWaiting: false,
                loading: false,
            });
        }
    }


}

export default FactoryPage;