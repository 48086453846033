import React from "react";
import { useParams } from "react-router-dom";
import { API } from "../config/api";
import { Alert, Card } from "react-bootstrap";
// import copy from "copy-to-clipboard";
import Logo from "../assets/logo.svg";
import "./ShortURLPage.css";


interface ShortURLPageProps {
    path: string;
}
interface ShortURLPageState {
    url: string;
    isIOS: boolean;
    isAndroid: boolean;
    isMobile: boolean;
    countdown: number;
}

class ShortURLPageDetail extends React.Component<ShortURLPageProps, ShortURLPageState> {

    constructor(props: ShortURLPageProps) {
        super(props);
        this.state = {
            url: "",
            isIOS: false,
            isAndroid: false,
            isMobile: false,
            countdown: 5,
        }
    }

    componentDidMount() {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        if (/android/i.test(userAgent)) {
            this.setState({
                isAndroid: true,
                isMobile: true,
            });
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            this.setState({
                isIOS: true,
                isMobile: true,
            });
        }
        // log path
        console.log(this.props.path);
        this._loadURL();
    }

    _loadURL = async () => {
        const res = await fetch(`${API}/project/getByShortName?shortName=${this.props.path}`)
        const data = await res.json()
        if (data.code === 1 && data.data.address) {
            const url = 'https://epump.fun/dt?address=' + data.data.address;
            this.setState({
                url: url
            });
            console.log(url);
            // count down after 5 seconds redirect
            if (url && url.startsWith("https://epump.fun/dt?address=")) {
                window.location.href = url;
                // setInterval(() => {
                //     if (this.state.countdown > 0) {
                //         this.setState({
                //             countdown: this.state.countdown - 1
                //         });
                //     } else {
                //         window.location.href = url;
                //     }
                // }, 1000);
            }
        }
    }

    render() {
        const { url, countdown } = this.state;
        return (
            <div>
                <div style={{
                    display: "flex",
                    padding: "1rem",
                    backgroundColor: "#333",
                    color: "#ffffff",

                }}>
                    <div style={{
                        // maxWidth: "800px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0 auto",
                        width: "100%",
                    }}>
                        <div className="headerLeft">
                            {/* logo */}
                            <img src={Logo} alt="logo" style={{
                                width: "2.2rem",
                                height: "2.2rem",
                                marginRight: "1rem",
                                cursor: "pointer",
                            }}
                                onClick={() => {
                                    window.location.href = '/';
                                }}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                }}>
                    <div style={{
                        maxWidth: "800px",
                        width: "100%",
                        margin: "1rem",
                    }}>
                        <Card>
                            <Card.Body>
                                <Alert variant="secondary">
                                    After {countdown} seconds, you will be redirected to the following URL.  <br />
                                    &gt;&gt;&gt; <span style={{
                                        textDecoration: "underline",
                                    }} className="link" >{url}</span>&lt;&lt;&lt;.  <br />
                                </Alert>
                                {/* <Card.Title>You can open the url with</Card.Title> */}

                                {/* <div>
                                    <Button variant="link" onClick={() => {
                                        const encodedDappUrl = encodeURIComponent(this.state.url);
                                        const deepLink = `okx://wallet/dapp/url?dappUrl=${encodedDappUrl}`;
                                        const encodedUrl = `https://www.okx.com/download?deeplink=${encodeURIComponent(deepLink)}`;
                                        window.location.href = encodedUrl;
                                    }}>OKX Web3 Wallet</Button>
                                </div>
                                <div>
                                    <Button variant="link" onClick={() => {
                                        const params = {
                                            "url": url,
                                            "chain": "base",
                                            "source": "Rocket Protocol"
                                        }
                                        window.location.href = `tpdapp://open?params=${encodeURIComponent(JSON.stringify(params))}`;
                                    }}>Token Pocket Wallet</Button>
                                </div>
                                <div>
                                    <Button variant="link" onClick={() => {
                                        // remove header https://
                                        const _url = url.substring(8);
                                        window.location.href = `https://metamask.app.link/dapp/${_url}`;
                                    }}>Metamask Wallet</Button>
                                </div>

                                <div>
                                    <Button variant="link" onClick={() => {
                                        window.location.href = `imtokenv2://navigate/DappView?url=${encodeURIComponent(url)}`;
                                    }}>imToken Wallet</Button>
                                </div>

                                <div>
                                    <Button variant="link" onClick={() => {
                                        window.location.href = `https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(this.state.url)}`;
                                    }}>Coinbase Wallet</Button>
                                </div> */}

                                {/* <div>
                                    <Button variant="link" onClick={() => {
                                        if (!this.isValid()) {
                                            alert("Invalid URL");
                                            return;
                                        }
                                        window.location.href = url;
                                    }}>Binance Web3 Wallet</Button>
                                </div> */}

                                {/* <Card.Title>You can </Card.Title>
                                <div>
                                    <Button variant="link" onClick={() => {
                                        window.location.href = url;
                                    }}>Open in Browser</Button>
                                </div>
                                <div>
                                    <Button variant="link" onClick={() => {
                                        copy(this.state.url);
                                    }}>Copy URL</Button>
                                </div> */}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

function ShortURLPage() {
    const { '*': url } = useParams();
    console.log(url);

    return (
        <ShortURLPageDetail path={url ?? ''} />
    );
}

export default ShortURLPage;